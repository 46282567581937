import AppPaper from "../../UI/AppPaper";
import {CircularProgress, styled} from "@mui/material";
import TextEditor from "../../Blocks/TextEditor";
import {
    IAdditionComment,
    useRemoveAdditionCommentMutation,
    useUpdateAdditionCommentMutation
} from "../../../services/tasks";
import {useEffect, useState} from "react";
import useDebounce from "../../../hooks/useDebounce";
import AdditionControls from "../AdditionControls";
import Modal from "../../UI/Modal";


const Text = styled('p')(() => ({
    marginTop: 0
}));

interface IAdditionCommentProps {
    iExecutor?: boolean
    comment: IAdditionComment
}

const AdditionComment = ({comment, iExecutor}: IAdditionCommentProps) => {

    const [content, setContent] = useState(comment.comment);
    const debouncedContent = useDebounce(content, 500);

    const [updateAdditionComment, {isLoading}] = useUpdateAdditionCommentMutation();

    useEffect(() => {
        if(JSON.stringify(debouncedContent) !== JSON.stringify(comment.comment)) {
            updateAdditionComment({
                commentId: comment.id,
                content
            });
        }
    }, [debouncedContent]);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const [removeAdditionComment, {isLoading: isRemoveCommentLoading}] = useRemoveAdditionCommentMutation();

    const deleteHandler = () => {
        removeAdditionComment(comment.id);
    }

    return (
        <AppPaper
            sx={{
                backgroundColor: '#F4F4F4',
                marginTop: 4,
                marginBottom: 2,
            }}
            label="Комментарий">
            <Modal
                open={isDeleteModalOpen}
                onClose={() => {
                    setIsDeleteModalOpen(false);
                }}
                loading={isRemoveCommentLoading}
                title="Are you sure ?"
                cancelButtonText="Cancel"
                submitButtonText="Delete"
                onSubmit={deleteHandler}
            />
            <TextEditor
                additionId={comment.id}
                uploadImagePath="addition/comment"
                readOnly={!iExecutor}
                data={content}
                onChange={setContent}
            />
            <AdditionControls
                isLoading={isRemoveCommentLoading}
                onDelete={iExecutor ? () => {
                    setIsDeleteModalOpen(true);
                } : undefined}
                creator={comment.creator}
                updater={comment.updater}
                createdAt={comment.time_create}
                updatedAt={comment.time_update}/>
            {
                isLoading && (
                    <CircularProgress
                        sx={{
                            position: 'absolute',
                            right: 5,
                            top: 5,
                            zIndex: 99
                        }}
                        color="primary"
                        size={25}
                    />
                )
            }
        </AppPaper>
    )
}

export default AdditionComment;