import { Navigate, Route, Routes } from "react-router-dom";
import { privateRoutes, publicRoutes } from "../routes";
import { LOGIN_ROUTE, PROJECTS_ROUTE } from "../utils/consts";
import { useAppSelector } from "../store";
import { useEffect, useMemo } from "react";
import { Provider, useDispatch } from "react-redux";
import { autoLogin } from "../features/authSlice";
import AppAlert from "./Alert";
import PrivateContainer from "./PrivateContainer";

const AppRotes = () => {
  const { token, isLoading } = useAppSelector((state) => state.auth);
  const auth = !!token;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(autoLogin());
  }, []);

  if (isLoading) return null;

  return auth ? (
    <PrivateContainer>
      <AppAlert />
      <Routes>
        {privateRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={route.component} />
        ))}
        <Route path="*" element={<Navigate to={PROJECTS_ROUTE} />} />
      </Routes>
    </PrivateContainer>
  ) : (
    <Routes>
      {publicRoutes.map((route) => (
        <Route key={route.path} path={route.path} element={route.component} />
      ))}
      <Route path="*" element={<Navigate to={LOGIN_ROUTE} />} />
    </Routes>
  );
};

export default AppRotes;
