import {ListItem, Menu, MenuItem, ListItemText, Badge, Button, Typography, Box} from "@mui/material";
import {
    useGetMessagesQuery,
    useGetNotificationsQuery,
    useReadAllNotificationsMutation,
    useReadNotificationMutation
} from "../../../services/notifications";
import {format} from "date-fns";
import NotificationListSkeleton from "../NotificationListSkeleton";

interface INotificationsListModalProps {
    anchorEl: null | HTMLElement
    onClose: () => void
}
const NotificationsListModal = ({anchorEl, onClose}: INotificationsListModalProps) => {
    const open = Boolean(anchorEl);

    const {data: notifications, isLoading, isFetching} = useGetMessagesQuery();

    const [readNotification, {isLoading: isReadLoading}] = useReadNotificationMutation();
    const [readAllNotifications, {isLoading: isReadAllLoading}] = useReadAllNotificationsMutation();

    return (
        <Menu
            onClose={onClose}
            open={open}
            anchorEl={anchorEl}>
            {
                isLoading || isFetching || isReadLoading || isReadAllLoading ?
                    <NotificationListSkeleton/> :
                    (
                        !!notifications?.length ?
                            <Box>
                                {
                                    notifications?.map(notification => (
                                        <MenuItem
                                            key={notification.id}
                                            sx={{
                                                ':hover': {
                                                    backgroundColor: '#fff'
                                                }
                                            }}
                                            disableRipple={true}>
                                            <ListItem
                                                sx={{display: 'block'}}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Badge color="error" badgeContent={notification.read ? 0 : ' '} variant="dot">
                                                            <Typography
                                                                dangerouslySetInnerHTML={{__html: notification.text}}
                                                                sx={{
                                                                    whiteSpace: 'normal',
                                                                    maxWidth: '450px',
                                                                }}
                                                            >
                                                            </Typography>
                                                        </Badge>
                                                    }
                                                    secondary={format(new Date(notification.created_at), "dd.MM.y HH:mm")}/>
                                                <Button
                                                    onClick={!notification.read ? () => readNotification({
                                                        notificationId: notification.id,
                                                        read: true
                                                    }) : undefined}
                                                    sx={{
                                                        marginTop: 1
                                                    }}
                                                    variant="outlined" size="small">
                                                    Прочитано
                                                </Button>
                                            </ListItem>
                                        </MenuItem>
                                    ))
                                }
                                <Button
                                    onClick={readAllNotifications}
                                    sx={{
                                        mx: 3,
                                        mt: 1
                                    }}
                                    variant="outlined"
                                    size="small">
                                    Прочитать все
                                </Button>
                            </Box>
                            :
                            <MenuItem>
                                <ListItem>
                                    <ListItemText primary="Нет уведомлений"></ListItemText>
                                </ListItem>
                            </MenuItem>
                    )
            }
        </Menu>
    )
}

export default NotificationsListModal;