import {Box, styled, IconButton, Menu, MenuItem, TextField} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {MouseEvent, useState} from "react";
import Modal from "../UI/Modal";
import {SubmitHandler, useForm} from "react-hook-form";
import ConstructionIcon from '@mui/icons-material/Construction';
import {Link} from "react-router-dom";


const StyledCard = styled(Box)(() => ({
    position: 'relative',
    borderRadius: '4px',
    background: 'linear-gradient(360deg, #e5e5e5 0%, rgba(217, 217, 217, 0) 102.3%)',
    boxShadow: '1px 1px 14px 3px rgba(0, 0, 0, .12)',
    overflow: 'hidden',
    minHeight: '180px',
    width: '100%',
    maxWidth: '230px'
}))

const CardImage = styled('img')(() => ({
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center'
}))

const CardIcon = styled(ConstructionIcon)(() => ({
    width: '60px',
    height: '60px',
    margin: 'auto'
}))

const CardTitle = styled('p')(({ theme }) => ({
    background: theme.palette.primary.main,
    color: '#fff',
    padding: '5px 10px',
    margin: 0,
    textTransform: 'uppercase',
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0
}));

const CardTop = styled(Box)(({}) => ({
    position: 'absolute',
    width: '100%',
    height: 40,
    left: 0,
    top: 0,
    background: 'linear-gradient(to bottom, rgba(60, 60, 60, .6), rgba(60, 60, 60, 0))'
}));

const MoreButton = styled(IconButton)(({theme}) => ({
    position: 'absolute',
    right: 0,
    top: 0,
    color: '#fff',
}));

const linkStyles = {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    color: 'inherit'
};

interface IProjectCardProps {
    id: string,
    onClick: () => void,
    name: string,
    image?: string,
    onDelete: () => void,
    onRename: (name: string) => void,
    onGuest: () => void,
    onUpdateImage: () => void,
    isPublished: boolean,
    onArchive: () => void,
    url: string
}

type Inputs = {
    projectName: string,
}

const ProjectCard = ({id, onClick, name, image, onDelete, onRename, onGuest, url, onUpdateImage, isPublished, onArchive}: IProjectCardProps) => {
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchor);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);

    const { register, handleSubmit, watch, formState: { errors } } = useForm<Inputs>();

    const openMenu = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setMenuAnchor(e.currentTarget);
    }

    const closeMenu = () => {
        setMenuAnchor(null);
    }

    const renameHandler: SubmitHandler<Inputs> = (data) => {
        if(data.projectName) {
            onRename(data.projectName);
            setIsRenameModalOpen(false);
        }
    }

    return (
        <>
            <Modal
                open={isRenameModalOpen}
                title="Переименовать проект"
                cancelButtonText="ОТМЕНИТЬ"
                submitButtonText="СОХРАНИТЬ"
                onSubmit={handleSubmit(renameHandler)}
                onClose={() => setIsRenameModalOpen(false)}
            >
                <TextField error={!!errors.projectName} helperText={errors.projectName ? "Это обязательное поле" : undefined} {...register("projectName", { required: true })} label="Название проекта"/>
            </Modal>
            <Modal
                open={isDeleteModalOpen}
                cancelButtonText="НЕ УДАЛЯТЬ"
                submitButtonText="УДАЛИТЬ"
                onSubmit={onDelete}
                onClose={() => setIsDeleteModalOpen(false)}>
                <div>
                    Удалить проект?
                </div>
            </Modal>
            <Menu
                anchorEl={menuAnchor}
                open={menuOpen}
                onClose={closeMenu}
            >
                <MenuItem onClick={() => setIsRenameModalOpen(true)}>Переименовать</MenuItem>
                {/*<MenuItem>В архив</MenuItem>*/}
                {/*<MenuItem>Передать права на проект</MenuItem>*/}
                {/*<MenuItem onClick={onGuest}>Доступы</MenuItem>*/}
                <MenuItem onClick={onUpdateImage}>Изменить изображение</MenuItem>
                {
                    isPublished ? <MenuItem onClick={onArchive}>Завершить</MenuItem> : <MenuItem onClick={onArchive}>Активировать</MenuItem>
                }
                <MenuItem onClick={() => setIsDeleteModalOpen(true)}>Удалить</MenuItem>
            </Menu>
            <StyledCard>
                <Link
                    style={linkStyles}
                    to={url}>
                    <CardTop/>
                    {image ? <CardImage src={image} alt="project"/> : <CardIcon/>}
                    <CardTitle>
                        {name}
                    </CardTitle>
                </Link>
                <MoreButton
                    onClick={openMenu}
                >
                    <MoreVertIcon/>
                </MoreButton>
            </StyledCard>
        </>
    );
}

export default ProjectCard;
