import {combineReducers} from 'redux';
import thunk from 'redux-thunk';
import {configureStore} from "@reduxjs/toolkit";
import {TypedUseSelectorHook, useSelector} from "react-redux";
import {projectApi} from '../services/projects';
import {authApi} from "../services/auth";
import authReducer from "../features/authSlice";
import appReducer from "../features/appSlice";
import { taskApi } from '../services/tasks';
import {chaptersApi} from "../services/chapters";
import {notificationsApi} from "../services/notifications";

const rootReducer = combineReducers({
    [projectApi.reducerPath]: projectApi.reducer,
    [taskApi.reducerPath]: taskApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [chaptersApi.reducerPath]: chaptersApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    auth: authReducer,
    app: appReducer,
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(
                thunk,
                projectApi.middleware,
                authApi.middleware,
                taskApi.middleware,
                chaptersApi.middleware,
                notificationsApi.middleware
            )
});

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;


export default store;
