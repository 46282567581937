import {Project} from "../services/projects";
import {useMemo} from "react";
import {IFilterItems} from "../components/Filters";

const useFilteredProjects = (filters: IFilterItems, projects?: Project[]): Project[] => {

    return useMemo(() => {
        if(!projects) return [];
        return projects.filter(project => {

           if(filters.completed.selected && !project.is_published) {
               return true;
           }

           if(filters.active.selected && project.is_published) {
               return true;
           }

           return false;
        });
    }, [projects, filters]);
}

export default useFilteredProjects;