import Layout from "../components/Layout";
import NavigationTitle from "../components/UI/NavigationTitle";
import UsersList from "../components/UsersList";
import Filters, {IFilterItems} from "../components/Filters";
import {MouseEvent, useState} from "react";
import {
    ITask, useAccountTaskMutation,
    useAddTaskExecutorMutation, useArchiveTaskMutation,
    useGetTaskPotentialExecutorsQuery,
    useGetTasksQuery, useRemoveTaskMutation,
    useUpdateTaskExecutorMutation
} from "../services/tasks";
import useFilteredTasks from "../hooks/useFilteredTasks";
import TasksListSkeleton from "../components/Tasks/TasksListSkeleton";
import TaskItem from "../components/Tasks/TaskItem";
import {useNavigate} from "react-router-dom";
import useTitle from "../hooks/useTitle";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Avatar,
    Box, IconButton, Menu, MenuItem, styled,
    Table,
    TableBody,
    TableHead,
    Typography
} from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import {HeadingTableCell, TableHeading} from "./ProjectTasks";
import UsersSelect from "../components/UsersSelect";
import Modal from "../components/UI/Modal";
import useTasksByProjects from "../hooks/useTasksByProjects";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {appAlert} from "../features/appSlice";
import {useDispatch} from "react-redux";
import { Link } from "react-router-dom";
import TableContainer from '@mui/material/TableContainer';
import TaskItemSkeleton from "../components/Tasks/TaskItemSkeleton";

const ExpandButton = styled(IconButton)(({theme}) => ({
    backgroundColor: theme.palette.success.light,
    '&:hover': {
        background: theme.palette.success.main
    }
}));

const AccordionHeading = styled(Box)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginRight: '320px',
    columnGap: '20px',
    [theme.breakpoints.down('md')]: {
        marginRight: '20px'
    }
}));

const AccordionHeadingText = styled(Typography)(({theme}) => ({
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
        fontSize: 14,
    }
}));

const Tasks = () => {
    useTitle("Tasks");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [updateExecutor] = useUpdateTaskExecutorMutation();

    const updateCompleteHandler = (executorId: number, isCompleted: boolean) => {
        updateExecutor({
            executorId, isCompleted
        }).unwrap()
            .catch((err) => {
                dispatch(appAlert({title: err.data.detail, status: 'error'}))
            });
    };

    const [isCreateTaskMenuOpen, setIsCreateTaskMenuOpen] = useState(false);
    const [taskMenuAnchor, setTaskMenuAnchor] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);
    const taskMenuOpen = Boolean(taskMenuAnchor);
    const [currentTask, setCurrentTask] = useState<null | ITask>(null);

    const openTaskMenu = (e: MouseEvent<HTMLElement>, task: ITask) => {
        e.preventDefault();
        setTaskMenuAnchor(
            taskMenuAnchor === null
                ? {
                    mouseX: e.clientX + 2,
                    mouseY: e.clientY - 6,
                }
                : null
        );
        setCurrentTask(task);
    };

    const [addTaskExecutor] = useAddTaskExecutorMutation();

    const [selectedTask, setSelectedTask] = useState<null | number>(null);
    const [selectedUser, setSelectedUser] = useState("");
    const {data: potentialExecutors} = useGetTaskPotentialExecutorsQuery(selectedTask as number, {skip: !selectedTask});

    const addExecutorHandler = () => {
        setSelectedTask(null);
        setSelectedUser('');
        if (selectedTask && selectedUser) {
            addTaskExecutor({
                taskId: selectedTask,
                userId: selectedUser,
            });
        }
    };


    const [filters, setFilters] = useState<IFilterItems>({
        iExecutor: {
            label: 'Я исполнитель',
            selected: false
        },
        iCreator: {
            label: 'Я создатель',
            selected: false
        },
        iManager: {
            label: 'Я мененджер',
            selected: false
        },
    });

    const {data: tasks, isLoading, isFetching} = useGetTasksQuery();

    const filteredTasks = useFilteredTasks(filters, tasks);

    const tasksByProjects = useTasksByProjects(filteredTasks);

    const [removeTask] = useRemoveTaskMutation();

    const removeTaskHandler = () => {
        if (currentTask) {
            removeTask(currentTask.id)
                .unwrap()
                .then(() => {
                    setTaskMenuAnchor(null);
                })
                .catch((err) => {
                    dispatch(appAlert({title: err.data.detail, status: 'error'}))
                });
        }
    };

    const [archiveTask] = useArchiveTaskMutation();

    const archiveHandler = (isPublished: boolean) => {
        if (currentTask) {
            setTaskMenuAnchor(null);
            archiveTask({
                taskId: currentTask.id,
                isPublished,
            })
                .unwrap()
                .catch((err) => {
                    dispatch(appAlert({title: err.data.detail, status: 'error'}))
                });
        }
    };

    const [accountTask] = useAccountTaskMutation();

    const accountHandler = (isAccounted: boolean) => {
        if (currentTask) {
            setTaskMenuAnchor(null);
            accountTask({
                taskId: currentTask.id,
                isAccounted,
            })
                .unwrap()
                .catch((err) => {
                    dispatch(appAlert({title: err.data.detail, status: 'error'}))
                });
        }
    };



    const changeTimerHandler = (executorId: number, isPlay: boolean) => {
        updateExecutor({
            executorId, isPlay
        }).unwrap()
            .catch((err) => {
                dispatch(appAlert({title: err.data.detail, status: 'error'}))
            });
    }

    const changeTimerManualHandler = (executorId: number, seconds: number) => {
        updateExecutor({
            executorId,
            totalTime: seconds
        }).unwrap()
            .catch((err) => {
                dispatch(appAlert({title: err.data.detail, status: 'error'}))
            });
    }

    return (
        <Layout
            title="Активные таски"
            navigation={<NavigationTitle title="Моя команда"/>}
            heading={<Filters navigationButtonText="TO PROJECTS" navigationLink="/projects" filters={filters} onChange={setFilters}/>}
            menu={<UsersList/>}
        >
            <Modal
                title="Добавить испонителя таска"
                submitButtonText="ДОБАВИТЬ"
                cancelButtonText="ОТМЕНИТЬ"
                open={!!selectedTask}
                onClose={() => setSelectedTask(null)}
                onSubmit={addExecutorHandler}
            >
                <UsersSelect
                    label="Исполнитель таска"
                    users={potentialExecutors}
                    onChange={(e) => {
                        const {
                            target: { value },
                        } = e;
                        setSelectedUser(value);
                    }}
                    value={selectedUser}
                />
            </Modal>
            <Menu
                open={taskMenuOpen}
                anchorReference="anchorPosition"
                anchorPosition={
                    taskMenuAnchor !== null
                        ? { top: taskMenuAnchor.mouseY, left: taskMenuAnchor.mouseX }
                        : undefined
                }
                onClose={() => setTaskMenuAnchor(null)}
            >
                {
                    currentTask && (
                        currentTask.is_published ?
                            <MenuItem onClick={() => archiveHandler(false)}>Завершить</MenuItem> :
                            <MenuItem onClick={() => archiveHandler(true)}>Активировать</MenuItem>
                    )
                }
                {
                    currentTask && !currentTask.is_published && (
                        currentTask.is_accounted ?
                            <MenuItem onClick={() => accountHandler(false)}>Отменить статус "оплачен"</MenuItem> :
                            <MenuItem onClick={() => accountHandler(true)}>Оплачен</MenuItem>
                    )
                }
                <MenuItem onClick={removeTaskHandler}>Удалить</MenuItem>
            </Menu>
            {
                isLoading || isFetching
                    ? <TasksListSkeleton/> :
                Object.keys(tasksByProjects).map((item, idx) => (
                    <Accordion
                        key={item}>
                        <AccordionSummary
                            expandIcon={<ExpandButton><ExpandMoreIcon sx={{color: '#fff'}} /></ExpandButton>}
                        >
                            <AccordionHeading>
                                <Link
                                    style={{
                                        textDecoration: 'none'
                                    }}
                                    to={`/projects/${tasksByProjects[item].project.id}/tasks`}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <Avatar sx={{marginRight: 3}} src={tasksByProjects[item].project.image}/>
                                        <AccordionHeadingText
                                            sx={{
                                                fontWeight: 700
                                            }}>{tasksByProjects[item].project.title}</AccordionHeadingText>
                                    </Box>
                                </Link>
                                <AccordionHeadingText>Всех: {tasksByProjects[item].tasks.length}</AccordionHeadingText>
                                <Typography></Typography>
                            </AccordionHeading>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer>
                                <Table sx={{
                                    minWidth: '1290px'
                                }}>
                                    <TableHead>
                                        <TableHeading>
                                            <HeadingTableCell>ID</HeadingTableCell>
                                            <HeadingTableCell>Краткое описание</HeadingTableCell>
                                            <HeadingTableCell>Выполнить до</HeadingTableCell>
                                            <HeadingTableCell>Время / статус / исполнитель</HeadingTableCell>
                                            <HeadingTableCell>Создатель</HeadingTableCell>
                                            <HeadingTableCell>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                }}>
                                                    <SortIcon/>
                                                </Box>
                                            </HeadingTableCell>
                                        </TableHeading>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            tasksByProjects[item].tasks.map(task => (
                                                task.loading ? <TaskItemSkeleton/> :
                                                <TaskItem
                                                    taskId={task.id}
                                                    completed={!task.is_published}
                                                    accounted={task.is_accounted}
                                                    onClick={() => {
                                                        navigate(`/projects/${task.project.id}/tasks/${task.id}`);
                                                    }}
                                                    href={`/projects/${task.project.id}/tasks/${task.id}`}
                                                    key={task.id}
                                                    title={task.title}
                                                    type="FULL"
                                                    executors={task.executors}
                                                    creatorAvatar={task.creator.avatar}
                                                    creatorName={task.creator.first_name ? task.creator.first_name : task.creator.email}
                                                    creatorId={task.creator.id}
                                                    onAdd={() => setSelectedTask(task.id)}
                                                    completeDate={task.time_to_completion}
                                                    onChangeTimer={changeTimerHandler}
                                                    onChangeTimerManual={changeTimerManualHandler}
                                                    onExecutorComplete={updateCompleteHandler}
                                                    onContextMenu={(e) => openTaskMenu(e, task)}
                                                />
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                ))
            }
        </Layout>
    );
}

export default Tasks;