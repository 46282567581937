import {MenuItem, Skeleton, ListItem} from "@mui/material";

const NotificationListSkeleton = () => {
    return (
        <>
            <MenuItem>
                <ListItem>
                    <Skeleton variant="rectangular" width={410} height={70} />
                </ListItem>
            </MenuItem>
            <MenuItem>
                <ListItem>
                    <Skeleton variant="rectangular" width={410} height={70} />
                </ListItem>
            </MenuItem>
            <MenuItem>
                <ListItem>
                    <Skeleton variant="rectangular" width={410} height={70} />
                </ListItem>
            </MenuItem>
        </>
    )
}

export default NotificationListSkeleton;