import {Box, List, ListItem, ListItemIcon} from "@mui/material";
import FileListItem from "./FileListItem";
import {useEffect, useRef, useState} from "react";
import useDnD from "../../../hooks/useDnD";
import {ITaskFile} from "../../../services/tasks";
import DnD from "../../UI/DnD";
import getFileName from "../../../utils/getFileName";
import OverLay from "../../UI/OverLay";
import ListItemText from "@mui/material/ListItemText";
import AddCircleIcon from '@mui/icons-material/AddCircle';

interface IFileListProps {
    files?: ITaskFile[],
    onAdd: (files: FileList) => void,
    onDelete: (fileId: number) => void,
    isLoading?: boolean
}

const FileList = ({files: defaultFiles, onAdd, onDelete, isLoading}: IFileListProps) => {
    const dropRef = useRef<HTMLDivElement>();
    const {dragging, files} = useDnD(dropRef);

    useEffect(() => {
        if(files) {
            onAdd(files);
        }
    }, [files]);

    return (
        <Box
            sx={{
                border: dragging ? '1px solid blue' : 'none'
            }}
            ref={dropRef}>
            {
                isLoading && <OverLay/>
            }
            {
                (!defaultFiles || defaultFiles.length === 0) ?
                    <DnD
                        onChange={(e) => {
                            if(e.target.files) {
                                onAdd(e.target.files);
                            }
                        }}
                        showInput={true}
                        dragging={dragging}/> :
                    <List
                        sx={{
                            p: 2,
                        }}
                    >
                        {
                            defaultFiles?.map((item) => (
                                <FileListItem
                                    key={item.id}
                                    name={getFileName(item.file)}
                                    url={item.file}
                                    onDelete={() => onDelete(item.id)}
                                />
                            ))
                        }
                        <ListItem sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <input
                                onChange={e => {
                                    if(e.target.files) {
                                        onAdd(e.target.files);
                                    }
                                }}
                                type="file"
                                multiple={true}
                                style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                left: 0,
                                top: 0,
                                opacity: 0,
                                cursor: 'pointer'
                            }}/>
                            <ListItemIcon>
                                <AddCircleIcon />
                            </ListItemIcon>
                            <ListItemText primary="Add Files"/>
                        </ListItem>
                    </List>
            }
        </Box>
    );
}

export default FileList;
