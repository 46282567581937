import Modal from "./UI/Modal";
import {SubmitHandler, useForm} from "react-hook-form";
import {Box, TextField} from "@mui/material";
import {useChangePasswordMutation} from "../services/auth";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {appAlert} from "../features/appSlice";

export type ChangePasswordInputs = {
    oldPassword: string
    newPassword: string
}

interface IChangePasswordModalProps {
    open: boolean
    onClose: () => void
}

const ChangePasswordModal = ({open, onClose}: IChangePasswordModalProps) => {
    const {register, handleSubmit, setError, formState: {errors}} = useForm<ChangePasswordInputs>();

    const [changePassword, {isLoading}] = useChangePasswordMutation();

    const dispatch = useDispatch();

    const onSubmit: SubmitHandler<ChangePasswordInputs> = (data) => {
        changePassword(data)
            .unwrap()
            .then(() => {
                dispatch(appAlert({title: 'Пароль успешно изменен'}));
                onClose();
            })
            .catch(err => {
                if(err.data.new_password) {
                    setError('newPassword', {type: 'custom', message: err.data.new_password[0]});
                }
                if(err.data.old_password) {
                    setError('oldPassword', {type: 'custom', message: err.data.old_password[0]});
                }
            });
    }

    return (
        <Modal
            title="Смена пароля"
            open={open}
            onClose={onClose}
            submitButtonText="СОХРАНИТЬ"
            cancelButtonText="ОТМЕНИТЬ"
            loading={isLoading}
            onSubmit={handleSubmit(onSubmit)}
        >
            <Box sx={{
                display: 'grid',
                gap: 3
            }}>
                <TextField error={!!errors.oldPassword}
                           helperText={errors.oldPassword ? errors.oldPassword.message : undefined}
                           {...register("oldPassword", {required: {value: true, message: "Это обязательное поле"}})}
                           label="Старый пароль"/>
                <TextField error={!!errors.newPassword}
                           helperText={errors.newPassword ? errors.newPassword.message : undefined}
                           {...register("newPassword", {required: {value: true, message: "Это обязательное поле"}})}
                           label="Новый пароль"/>
            </Box>
        </Modal>
    )
}

export default ChangePasswordModal;