import {Box, CircularProgress, styled, TextField} from "@mui/material";
import {SubmitHandler, useForm} from "react-hook-form";
import {useUpdateTaskMutation} from "../../services/tasks";
import {useDispatch} from "react-redux";
import {appAlert} from "../../features/appSlice";

const Wrapper = styled(Box)(() => ({
    position: 'relative'
}));

interface Inputs {
    field: string
}

interface ITaskFieldProps {
    taskId?: string|number;
    value?: string;
    field: 'title' | 'linkToResource';
    label: string
}

const TaskField = ({taskId, value, field, label}: ITaskFieldProps) => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm<Inputs>({
        defaultValues: {
            field: value
        }
    });

    const dispatch = useDispatch();

    const [updateTask, {isLoading}] = useUpdateTaskMutation();
    const saveHandler: SubmitHandler<Inputs> = (data) => {
         if(taskId && value !== data.field) {
             updateTask({
                id: taskId,
                [field]: data.field
             })
                 .unwrap()
                 .catch(err => {
                     dispatch(appAlert({title: err.data.detail, status: 'error'}))
                 });
         }
    }

    return (
        <Wrapper>
            <TextField
                disabled={isLoading}
                error={!!errors.field}
                helperText={errors.field ? errors.field.message : undefined}
                {...register("field", { required: {value: true, message: 'Это обязательное поле'} })}
                sx={{
                    width: '100%'
                }}
                onBlur={handleSubmit(saveHandler)}
                label={label}/>
            {
                isLoading && (
                    <CircularProgress
                        sx={{
                            position: 'absolute',
                            right: 5,
                            top: 5,
                            zIndex: 99
                        }}
                        color="primary"
                        size={15}
                    />
                )
            }
        </Wrapper>
    )
}

export default TaskField;