import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/dist/query/react";
import { RootState } from "../store";
import { OutputData } from "@editorjs/editorjs";
import {IProjectData} from "./projects";


export interface IUser {
  id: number,
  email: string,
  first_name?: string,
  last_name?: string,
  phone?: string,
  avatar?: string
}

export interface ITaskFile {
  id: number,
  file: string
}

export interface ITask {
  id: number,
  updater: IUser,
  creator: IUser,
  executors: IExecutor[],
  time_create: string,
  time_update: string,
  title: string,
  link_to_resource: string,
  time_to_completion: string,
  image?: string,
  is_published: boolean,
  is_accounted: boolean,
  project: IProjectData,
  files: ITaskFile[],
  loading?: boolean
}

export interface IExecutor {
  id: number
  executor: IUser
  is_completed: boolean
  is_play: boolean
  total_time: number
}

export type Boards = "MEDIA" | "DOCKS" | "COMMENT" | "EDITOR";

export interface BoardFile {
  name: string;
  url: string;
}

export interface Board {
  files: BoardFile[];
  content?: OutputData;
  id: string;
  name: string;
  creator: string;
  parent_project: string;
  parent_task: string;
  sort: number;
  type: Boards;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
}

interface ParentProject {
  id: string;
  name: string;
}

interface FullDesc {
  time: string;
  blocks: any[];
  version: string;
}

export interface User {
  id: string;
  name: string;
  email: string;
  avatar_url?: string;
}

export interface Executor {
  completed: boolean;
  play: boolean;
  time: number;
  id: string;
  user: User;
}

interface ICreateTaskData {
  project: string|number;
  title: string,
  linkToResource: string,
  dateTo: string,
}

interface IUpdateTaskData {
  id: string|number;
  title?: string;
  linkToResource?: string;
  dateTo?: string;
}

interface IUpdateTaskBody {
  title?: string;
  link_to_resource?: string;
  time_to_completion?: string;
}

interface IArchiveTaskData {
  taskId: number;
  isPublished: boolean;
}

interface IAccountTaskData {
  taskId: number;
  isAccounted: boolean;
}

interface IAddTaskExecutorData {
  taskId: number;
  userId: string;
}

interface IAddTaskBoardData {
  taskId: string|number;
  content: OutputData;
}

export interface IUpdateMediaBoardData {
  board_id: string;
  images: FileList;
}

export interface IUpdateDocksBoardData {
  board_id: string;
  files: FileList;
}

export interface IDeleteTaskBoardElementData {
  board_id: string;
  url_for_delete: string;
}

export interface IUpdateCommentBoardData {
  board_id: string;
  content: OutputData;
}

interface IAddTaskImageData {
  id: string|number;
  image: File;
}

interface IUpdateBoardsSortData {
  board_id: string;
  sort: number;
}

interface IUpdateTaskTimerData {
  taskId: string;
  isPlay: boolean;
}

interface IUpdateTaskCompletedData {
  taskId: number;
  isCompleted: boolean;
}

interface IUpdateTaskExecutorData {
  executorId: number,
  isCompleted?: boolean,
  isPlay?: boolean,
  totalTime?: number
}

interface IUpdateTaskBoardData {
  id: number;
  content: OutputData;
}

export interface IAddition {
  id: number
  creator: IUser,
  updater: IUser|null,
  task: number
  addition: OutputData
  comments: IAdditionComment[]
  time_create: string
  time_update: string
}

export interface IAdditionComment {
  id: number
  creator: IUser
  updater: any
  addition_task: number
  comment: OutputData
  time_create: string
  time_update: string
}

export interface IAddFilesData {
  taskId: string|number;
  files: FileList
}

export interface IAddAdditionCommentData {
  additionId: number
  content: OutputData
}

export interface IUpdateAdditionCommentData {
  commentId: number
  content: OutputData
}

export const taskApi = createApi({
  reducerPath: "taskApi",
  tagTypes: ["Task", "Addition"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const token = (getState() as RootState).auth.token;
      headers.set("Authorization", `Token ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getTasks: builder.query<ITask[], void>({
      query: () => "project/task/list",
      providesTags: ["Task"],
    }),
    getProjectTasks: builder.query<ITask[], string>({
      query: (projectId) => `project/${projectId}/task/list`,
      providesTags: ["Task"],
    }),
    getTask: builder.query<ITask, string>({
      query: (id) => `project/task/${id}/get`,
      providesTags: ["Task"],
    }),

    //executors
    getTaskPotentialExecutors: builder.query<IUser[], number>({
      query: (id) => `/project/task/${id}/potential_executor/list`,
      providesTags: ["Task"],
    }),
    addTaskExecutor: builder.mutation({
      query: (data: IAddTaskExecutorData) => ({
        url: `project/task/${data.taskId}/executor/${data.userId}/post`,
        method: "POST",
      }),
      invalidatesTags: ["Task"],
    }),
    updateTaskExecutor: builder.mutation({
      query: (data: IUpdateTaskExecutorData) => ({
        url: `project/task/executor/${data.executorId}/path`,
        method: "PATCH",
        body: {
          is_completed: data.isCompleted,
          is_play: data.isPlay,
          total_time_manual: data.totalTime
        }
      }),
      invalidatesTags: ["Task"],
    }),
    removeTaskExecutor: builder.mutation<string, number>({
      query: (id) => ({
        url: `project/task/executor/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["Task"],
    }),

    createTask: builder.mutation<ITask, ICreateTaskData>({
      query: (data) => ({
        url: "/project/task/post",
        method: "POST",
        body: {
          title: data.title,
          project: data.project,
          link_to_resource: data.linkToResource,
          time_to_completion: data.dateTo,
        },
      }),
      invalidatesTags: ["Task"],
    }),
    updateTask: builder.mutation({
      query: (data: IUpdateTaskData) => {
        const body: IUpdateTaskBody = {};
        if(data.title) {
          body.title = data.title;
        }
        if(data.linkToResource) {
          body.link_to_resource = data.linkToResource;
        }
        if(data.dateTo) {
          body.time_to_completion = data.dateTo;
        }
        return ({
          url: `project/task/${data.id}/path`,
          method: "PATCH",
          body,
        })
      },
      invalidatesTags: ["Task"],
    }),
    addTaskImage: builder.mutation({
      query: (data: IAddTaskImageData) => {
        let formData = new FormData();
        formData.append("image", data.image);
        return {
          url: `/project/task/${data.id}/image/path`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["Task"],
    }),
    removeTask: builder.mutation({
      query: (id: number) => ({
        url: `project/task/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["Task"],
    }),
    archiveTask: builder.mutation({
      query: (data: IArchiveTaskData) => ({
        url: `project/task/${data.taskId}/published/path`,
        method: "PATCH",
        body: {
          is_published: data.isPublished
        },
      }),
      // async onQueryStarted({taskId, isPublished}, { dispatch, queryFulfilled }) {
      //   const patchResult = dispatch(
      //       taskApi.util.updateQueryData('getProjectTasks', '5', (draft) => {
      //         return draft.map(task => task.id === taskId ? {...task, loading: true} : task);
      //       })
      //   )
      //   try {
      //     await queryFulfilled
      //     dispatch(
      //         taskApi.util.updateQueryData('getProjectTasks', '5', (draft) => {
      //           return draft.map(task => ({...task, is_published: task.id !== taskId ? task.is_published : isPublished, loading: false}));
      //         })
      //     )
      //   } catch {
      //     dispatch(taskApi.util.invalidateTags(['Task']))
      //   }
      // },
      invalidatesTags: ["Task"],
    }),
    accountTask: builder.mutation({
      query: (data: IAccountTaskData) => ({
        url: `project/task/${data.taskId}/accounted/path`,
        method: "PATCH",
        body: {
          is_accounted: data.isAccounted
        },
      }),
      invalidatesTags: ["Task"],
    }),
    //additions
    getTaskAdditions: builder.query<IAddition[], string>({
      query: (id) => `project/task/${id}/addition/list`,
      providesTags: ["Addition"],
    }),
    addTaskBoard: builder.mutation({
      query: (data: IAddTaskBoardData) => ({
        url: `/project/task/${data.taskId}/addition/post`,
        method: "POST",
        body: {
          addition: data.content
        },
      }),
      invalidatesTags: ["Addition"],
    }),
    updateTaskBoard: builder.mutation({
      query: (data: IUpdateTaskBoardData) => ({
        url: `/project/task/addition/${data.id}/path`,
        method: "PATCH",
        body: {
          addition: data.content
        },
      }),
      invalidatesTags: ["Addition"],
    }),
    removeTaskBoard: builder.mutation({
      query: (id: number) => ({
        url: `project/task/addition/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["Addition"],
    }),
    addAdditionComment: builder.mutation({
      query: (data: IAddAdditionCommentData) => ({
        url: `/project/task/addition/${data.additionId}/comment/post`,
        method: "POST",
        body: {
          comment: data.content
        },
      }),
      invalidatesTags: ["Addition"],
    }),
    updateAdditionComment: builder.mutation({
      query: (data: IUpdateAdditionCommentData) => ({
        url: `/project/task/addition/comment/${data.commentId}/path`,
        method: "PATCH",
        body: {
          comment: data.content
        },
      }),
      invalidatesTags: ["Addition"],
    }),
    removeAdditionComment: builder.mutation({
      query: (id: number) => ({
        url: `project/task/addition/comment/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["Addition"],
    }),


    //task files
    addTaskFiles: builder.mutation({
      query: (data: IAddFilesData) => {
        let formData = new FormData();
        for (let i = 0; i < data.files.length; i++) {
          formData.append("file", data.files[i]);
        }
        return {
          url: `project/task/${data.taskId}/file/path`,
          method: "PATCH",
          body: formData
        }
      },
      invalidatesTags: ["Task"],
    }),
    removeTaskFile: builder.mutation({
      query: (id: number) => ({
        url: `project/task/file/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["Task"],
    }),

    deleteTaskBoardElement: builder.mutation({
      query: (data: IDeleteTaskBoardElementData) => ({
        url: "/task/board/element",
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Task"],
    }),
    updateMediaBoard: builder.mutation({
      query: (data: IUpdateMediaBoardData) => {
        let formData = new FormData();
        formData.append("board_id", data.board_id);
        for (let i = 0; i < data.images.length; i++) {
          formData.append("images", data.images[i]);
        }
        return {
          url: "/task/board-media",
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: ["Task"],
    }),
    updateDocksBoard: builder.mutation({
      query: (data: IUpdateDocksBoardData) => {
        let formData = new FormData();
        formData.append("board_id", data.board_id);
        for (let i = 0; i < data.files.length; i++) {
          formData.append("docks", data.files[i]);
        }
        return {
          url: "/task/board-docks",
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: ["Task"],
    }),
    updateCommentBoard: builder.mutation({
      query: (data: IUpdateCommentBoardData) => ({
        url: "/task/board-comment",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Task"],
    }),
    updateEditorBoard: builder.mutation({
      query: (data: IUpdateCommentBoardData) => ({
        url: "/task/board-editor",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Task"],
    }),
    updateBoardsSort: builder.mutation({
      query: (data: IUpdateBoardsSortData[]) => ({
        url: "/task/board/sort",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Task"],
    }),
    updateTaskTimer: builder.mutation({
      query: (data: IUpdateTaskTimerData) => ({
        url: `project/task/executor/${data.taskId}/path`,
        method: "PATCH",
        body: {
          is_play: data.isPlay
        },
      }),
      invalidatesTags: ["Task"],
    }),
    updateTaskComplete: builder.mutation({
      query: (data: IUpdateTaskCompletedData) => ({
        url: `project/task/executor/${data.taskId}/path`,
        method: "PATCH",
        body: {
          is_competed: data.isCompleted
        },
      }),
      invalidatesTags: ["Task"],
    }),
  }),
});

export const {
  useGetTasksQuery,
  useGetProjectTasksQuery,
  useGetTaskQuery,
  useGetTaskPotentialExecutorsQuery,
  useUpdateTaskExecutorMutation,
  useCreateTaskMutation,
  useUpdateTaskMutation,
  useRemoveTaskMutation,
  useArchiveTaskMutation,
  useAccountTaskMutation,
  useAddTaskImageMutation,
  useAddTaskExecutorMutation,
  useRemoveTaskExecutorMutation,
  useUpdateMediaBoardMutation,
  useUpdateDocksBoardMutation,
  useDeleteTaskBoardElementMutation,
  useRemoveTaskBoardMutation,
  useAddTaskBoardMutation,
  useUpdateCommentBoardMutation,
  useUpdateEditorBoardMutation,
  useUpdateBoardsSortMutation,
  useUpdateTaskTimerMutation,
  useUpdateTaskCompleteMutation,
  useGetTaskAdditionsQuery,
  useUpdateTaskBoardMutation,
  useAddTaskFilesMutation,
  useRemoveTaskFileMutation,
  useAddAdditionCommentMutation,
  useUpdateAdditionCommentMutation,
  useRemoveAdditionCommentMutation
} = taskApi;
