import { createSlice } from "@reduxjs/toolkit";

type AppSlice = {
  alertTitle: string;
  alertStatus: "success" | "info" | "warning" | "error";
  opened: boolean;
  isSidebarOpened: boolean;
};

const initialState: AppSlice = {
  alertTitle: "",
  alertStatus: "success",
  opened: false,
  isSidebarOpened: localStorage.getItem('isSidebarOpened') === 'false' ? false : true,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    appAlert: (state, action) => {
      state.opened = true;
      state.alertTitle = action.payload.title;
      state.alertStatus = action.payload.status ? action.payload.status : "success";
    },
    closeAlert: (state) => {
      state.opened = false;
    },
    openSidebar: (state) => {
      state.isSidebarOpened = true;
      localStorage.setItem('isSidebarOpened', 'open');
    },
    closeSidebar: (state) => {
      state.isSidebarOpened = false;
      localStorage.setItem('isSidebarOpened', 'false');
    },
    toggleSidebar: (state) => {
      localStorage.setItem('isSidebarOpened', `${!state.isSidebarOpened}`);
      state.isSidebarOpened = !state.isSidebarOpened;
    }
  },
});

export default appSlice.reducer;

export const {
  appAlert,
  closeAlert,
  openSidebar,
  closeSidebar,
  toggleSidebar
} = appSlice.actions;
