import {format, addSeconds} from "date-fns";
import {Box, styled, TextField, Typography} from "@mui/material";
import {useState, MouseEvent, FormEvent} from "react";
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import secondsToHms from "../../utils/secondsToHms";


const timeFieldStyles = {
    '.MuiInputBase-input': {
        padding: '3px',
        fontSize: 12,
        maxWidth: 50
    }
}

interface ITaskItemTimerProps {
    totalTime: number,
    onSubmit: (seconds: number) => void,
    disabled?: boolean
}
const TaskItemTimer = ({totalTime, onSubmit, disabled}: ITaskItemTimerProps) => {

    const [isInputOpen, setIsInputOpen] = useState(false);
    const [time, setTime] = useState<Date | null>(null);

    const doubleClickHandler = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setIsInputOpen(prev => !prev);
    }

    const submitHandler = (e: FormEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsInputOpen(false);
        if(time) {
            const hours = format(time, 'H');
            const minutes = format(time, 'm')
            const seconds = format(time, 's')
            const totalSeconds = (Number(hours) * 3600) + (Number(minutes) * 60) + Number(seconds);
            onSubmit(totalSeconds);
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box
                component="form"
                onSubmit={submitHandler}
                onClick={(e) => {e.stopPropagation();}}
                onDoubleClick={disabled ? undefined : doubleClickHandler}>
                {
                    isInputOpen ? <TimeField
                            sx={timeFieldStyles}
                            format="HH:mm:ss"
                            value={time}
                            onChange={(newValue) => setTime(newValue)}
                        /> :
                        <Typography component='p' fontSize={14} color={disabled ? '#979797' : '#D500F9 '}>
                            {
                                totalTime > 0 ?
                                    secondsToHms(totalTime) :
                                    '--:--'
                            }
                        </Typography>
                }
            </Box>
        </LocalizationProvider>
    )
}

export default TaskItemTimer;