import { OutputData } from '@editorjs/editorjs';
import {
    Box,
    Button,
    IconButton,
    Paper,
    styled,
    TextField, Typography
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {useMemo, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {
    IAddition, ITask, ITaskFile, useAddTaskFilesMutation, useRemoveTaskFileMutation, useUpdateTaskMutation
} from "../services/tasks";
import Addition from "./Addition";
import generateEditorOutputData from "../utils/generateEditorOutputData";
import TaskField from "./Tasks/TaskField";
import TaskDateField from "./Tasks/TaskDateField";
import TaskImageField from "./Tasks/TaskImageField";
import FileList from '../components/Blocks/FileList'
import AppPaper from "./UI/AppPaper";
import {useGetUserQuery} from "../services/auth";
import {appAlert} from "../features/appSlice";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";

const Wrapper = styled(Box)(({theme}) => ({
    position: 'relative',
    [theme.breakpoints.down('md')]: {
        marginLeft: '-'+theme.spacing(9)
    },
    [theme.breakpoints.down('sm')]: {
        marginLeft: '-'+theme.spacing(7)
    }
}));

const TopLine = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        rowGap: 30
    }
}));

const Main = styled(Box)(({theme}) => ({
    marginTop: 30,
    display: 'grid',
    gap: '30px',
    alignItems: 'flex-start',
    gridTemplateColumns: '1fr 1fr 1fr',
    [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: '1fr'
    }
}))

const AddButton = styled(IconButton, {})(({theme}) => ({
    background: theme.palette.success.main,
    color: '#fff',
    '&:hover': {
        background: theme.palette.success.light
    }
}));

const OverLay = styled(Box)(() => ({
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 2,
    background: 'rgba(255, 255, 255, 0.7)'
}));

const ButtonWrapper = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px'
}));

export type SaveTaskValues = {
    dateTo: Date,
    title: string,
    linkToResource: string,
    image: File | null,
}

interface ITaskBodyProps {
    task?: ITask,
    taskId?: string|number,
    onSave: (values: SaveTaskValues) => void,
    onAddBoard?: (data: OutputData) => void,
    title?: string,
    linkToResource?: string,
    timeToCompletion?: string,
    image?: string,
    isLoading?: boolean,
    additions?: IAddition[],
    isAdditionAdding?: boolean,
    files: ITaskFile[],
    cancelHref: string
}

type Inputs = {
    title: string,
    linkToResource: string,
}

const TaskBody = (props: ITaskBodyProps) => {
    const {
        task,
        taskId,
        onSave,
        title,
        linkToResource,
        timeToCompletion,
        image,
        onAddBoard,
        isLoading,
        files,
        cancelHref
    } = props;

    const [date, setDate] = useState<Date | null>(timeToCompletion ? new Date(timeToCompletion) : new Date());

    const [newImage, setNewImage] = useState<File | null>(null);

    const { register, handleSubmit, watch, formState: { errors } } = useForm<Inputs>({
        defaultValues: {
            title,
            linkToResource
        }
    });

    const clickAddButtonHandler = () => {
        if(onAddBoard) {
            onAddBoard(generateEditorOutputData());
        }
    }

    const saveHandler: SubmitHandler<Inputs> = (data) => {
        if(date) {
            onSave({
                title: data.title,
                linkToResource: data.linkToResource,
                dateTo: date,
                image: newImage
            });
        }
    }

    const dispatch = useDispatch();
    const [addTaskFiles, {isLoading: isAddFilesLoading}] = useAddTaskFilesMutation();

    const addFilesHandler = (files: FileList) => {
        if(taskId) {
            addTaskFiles({
                taskId,
                files
            }).unwrap()
                .catch(err => {
                    dispatch(appAlert({title: err.data.detail, status: 'error'}))
                });
        }
    }

    const [removeTaskFile, {isLoading: isRemoveFileLoading}] = useRemoveTaskFileMutation();

    const deleteFileHandler = (fileId: number) => {
        removeTaskFile(fileId);
    }

    const {data: user} = useGetUserQuery();

    const iExecutor = useMemo(() => {
        if(!task || !user) return false;
        return !!task.executors.find(executor => executor.executor.id === user.id);
    }, [task]);

    return (
        <Wrapper>
            {
                isLoading && <OverLay/>
            }
            <TopLine>
                <Link to={cancelHref}>
                    <Button
                        variant="outlined">
                        Project tasks
                    </Button>
                </Link>
                <TaskDateField taskId={taskId} dateTo={timeToCompletion}/>
                <Typography>
                    ID: {task?.id}
                </Typography>
            </TopLine>
            <Main>
                <Box
                    sx={{
                        display: 'grid',
                        rowGap: 3,
                    }}
                >
                    <Paper elevation={3}>
                        <TaskField label="Краткое описание" taskId={taskId} value={title} field="title"/>
                    </Paper>
                    <AppPaper label="Приложить документы">
                        <FileList isLoading={isAddFilesLoading || isRemoveFileLoading} files={files} onAdd={addFilesHandler} onDelete={deleteFileHandler}/>
                    </AppPaper>
                    <Paper elevation={3}>
                        <TaskField label="Ссылка на ресурс" taskId={taskId} value={linkToResource} field="linkToResource"/>
                    </Paper>
                </Box>
            </Main>
            {
                props.additions &&
                props.additions.map((addition, idx) => (
                    <Addition iExecutor={iExecutor} key={addition.id} index={idx} addition={addition}/>
                ))
            }
            <ButtonWrapper>
                <AddButton
                    disabled={props.isAdditionAdding}
                    onClick={clickAddButtonHandler}
                    size="large">
                    <AddIcon/>
                </AddButton>
            </ButtonWrapper>
        </Wrapper>
    );
}

export default TaskBody;
