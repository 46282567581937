import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";
import {IUser} from "./tasks";

export interface Project {
  id: string;
  owner: IUser;
  title: string;
  image: string;
  time_create: string;
  time_update: string;
  is_published: boolean;
}

export interface IProjectData {
  id: number
  owner: IUser
  title: string
  image: string
  time_create: string
  time_update: string
  is_published: boolean
}

interface IRenameProjectData {
  id: string;
  name: string;
}

type AddProjectData = {
  name: string;
};

export interface IProjectUser {
  id: string,
  user: IUser,
  project: 30,
  role: string
}

interface IAddProjectUserData {
  userId: string;
  projectId: string;
}

interface IUpdateProjectUserData {
  id: number | string;
  role: string;
}

export interface IGuestProject {
  name: string;
  slug: string;
}

interface IChangeGuestProjectPasswordData {
  project_id: string;
  new_pass: string;
}

interface IUpdateProjectPublishedData {
  projectId: number|string;
  isPublished: boolean
}

export const projectApi = createApi({
  reducerPath: "projectApi",
  tagTypes: ["Project", "ProjectUser"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const token = (getState() as RootState).auth.token;
      headers.set("Authorization", `Token ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getProjects: builder.query<Project[], void>({
      query: () => "project/list",
      providesTags: ["Project"],
    }),
    getProject: builder.query<IProjectData, string>({
      query: (id) => `project/${id}/get`,
    }),
    addProject: builder.mutation<Project, AddProjectData>({
      query: (data) => {
        return {
          url: "project/post",
          method: "POST",
          body: {
            title: data.name,
          },
        };
      },
      invalidatesTags: ["Project"],
    }),
    addProjectImage: builder.mutation({
      query: ({id, image}: {id: string, image: File}) => {
        let formData = new FormData();
        formData.append("image", image);
        return {
          url: `project/${id}/image/path`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["Project"],
    }),
    removeProject: builder.mutation({
      query: (id: string) => ({
        url: `project/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    renameProject: builder.mutation({
      query: ({id, name}: IRenameProjectData) => ({
        url: `project/${id}/path`,
        method: "PATCH",
        body: {
          title: name
        },
      }),
      invalidatesTags: ["Project"],
    }),
    getProjectUsers: builder.query<IProjectUser[], string>({
      query: (id) => `project/${id}/participant/list`,
      providesTags: ["ProjectUser"]
    }),
    getProjectPotentialParticipants: builder.query<IUser[], string>({
      query: (id) => `project/${id}/potential_participant/list`,
      providesTags: ["ProjectUser"]
    }),
    addProjectUser: builder.mutation({
      query: (data: IAddProjectUserData) => ({
        url: `project/${data.projectId}/participant/${data.userId}/post`,
        method: "POST",
        body: {
          role: "BRO"
        },
      }),
      invalidatesTags: ["ProjectUser"],
    }),
    updateProjectUser: builder.mutation({
      query: (data: IUpdateProjectUserData) => ({
        url: `project/participant/${data.id}/path`,
        method: "PATCH",
        body: {
          role: data.role
        },
      }),
      invalidatesTags: ["ProjectUser"],
    }),
    removeProjectUser: builder.mutation({
      query: (id: string|number) => ({
        url: `project/participant/${id}/delete/`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProjectUser"],
    }),
    getGuestProject: builder.query<IGuestProject, string>({
      query: (projectId) => "guest/" + projectId,
    }),
    changeGuestProjectPassword: builder.mutation({
      query: (data: IChangeGuestProjectPasswordData) => ({
        url: "/project/guest/pass",
        method: "PUT",
        body: data,
      }),
    }),
    updateProjectPublished: builder.mutation({
      query: (data: IUpdateProjectPublishedData) => ({
        url: `project/${data.projectId}/published/path/`,
        body: {
          is_published: data.isPublished
        },
        method: "PATCH",
      }),
      invalidatesTags: ["Project"],
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useGetProjectQuery,
  useAddProjectMutation,
  useAddProjectImageMutation,
  useUpdateProjectUserMutation,
  useAddProjectUserMutation,
  useRemoveProjectMutation,
  useRemoveProjectUserMutation,
  useRenameProjectMutation,
  useGetProjectUsersQuery,
  useGetProjectPotentialParticipantsQuery,
  useGetGuestProjectQuery,
  useChangeGuestProjectPasswordMutation,
  useUpdateProjectPublishedMutation
} = projectApi;
