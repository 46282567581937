export const LOGIN_ROUTE = '/login'
export const PROJECTS_ROUTE = '/projects'
export const TASKS_ROUTE = '/tasks'
export const PROJECT_TASKS_ROUTE = '/projects/:id/tasks'
export const PROJECT_CHAPTERS_ROUTE = '/projects/:slug/chapters'
export const PROJECT_CHAPTER_ROUTE = '/projects/:slug/chapters/:id'
export const CREATE_TASK_ROUTE = '/projects/:slug/create-task'
export const USERS_ROUTE = '/users'
// export const TASK_ROUTE = '/projects/:projectId/tasks/:taskId'
export const TASK_ROUTE = '/projects/:projectId/tasks/:taskId'
