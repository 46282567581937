import {Box, Skeleton} from "@mui/material";

const TaskBodySkeleton = () => {
    return (
       <>
           <Box
               sx={{
                   display: 'flex',
                   alignItems: 'center',
                   justifyContent: 'space-between'
               }}
           >
               <Skeleton
                   width={87}
                   height={36}
                   sx={{
                       borderRadius: '4px'
                   }}
                   variant="rectangular"
               />
               <Skeleton
                   sx={{
                       borderRadius: '4px'
                   }}
                   width={250}
                   height={56}
                   variant="rectangular"
               />
               <Skeleton
                   sx={{
                       borderRadius: '4px'
                   }}
                   width={87}
                   height={36}
                   variant="rectangular"
               />
           </Box>
           <Box
               sx={{
                   width: '100%',
                   display: 'flex',
                   columnGap: '24px',
                   mt: '24px'
               }}
           >
               <Box
                   sx={{
                       width: '33%',
                   }}
               >
                   <Skeleton
                       sx={{
                           borderRadius: '4px',
                           mb: '24px',
                       }}
                       height={100}
                       variant="rectangular"
                   />
                   <Skeleton
                       sx={{
                           borderRadius: '4px',
                           mb: '24px',
                       }}
                       height={166}
                       variant="rectangular"
                   />
                   <Skeleton
                       sx={{
                           borderRadius: '4px',
                       }}
                       height={56}
                       variant="rectangular"
                   />
               </Box>
               <Skeleton
                   sx={{
                       borderRadius: '4px',
                       flexGrow: 1,
                   }}
                   height={370}
                   variant="rectangular"
               />
           </Box>
       </>
    );
}

export default TaskBodySkeleton;
