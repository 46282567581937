import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {RootState} from "../store";


interface INotification {
    id: number,
    recipient: number,
    text: string,
    created_at: string,
    read: boolean
}

interface IReadNotificationData {
    notificationId: number,
    read: boolean
}

export interface IPushSubscriptionData {
    status_type: string,
    subscription: PushSubscriptionJSON,
    user_agent: string,
    browser?: string
}

export const notificationsApi = createApi({
    reducerPath: "notificationsApi",
    tagTypes: ["Notification"],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders: (headers, {getState, endpoint}) => {
            const token = (getState() as RootState).auth.token;
            headers.set("Authorization", `Token ${token}`);
            headers.set("content-type", 'application/json');
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getMessages: builder.query<INotification[], void>({
            // providesTags: ["Notification"],
            query: () => 'notification/list',
            async onCacheEntryAdded(
                arg,
                { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState }
            ) {
                // create a websocket connection when the cache subscription starts
                const token = (getState() as RootState).auth.token;
                const ws = new WebSocket(`ws://dev-backend.real-work.ru/ws/?token=${token}`)
                ws.onopen = function () {
                    ws.send(
                        JSON.stringify({
                            "action": "subscribe_to_notification",
                            "request_id": Date.now()
                        })
                    );
                }
                try {
                    // wait for the initial query to resolve before proceeding
                    await cacheDataLoaded

                    // when data is received from the socket connection to the server,
                    // if it is a message and for the appropriate channel,
                    // update our query result with the received message
                    const listener = (event: MessageEvent) => {
                        const data = JSON.parse(event.data)
                        // if (data.action !== 'retrieve') return


                        updateCachedData((draft) => {
                            draft.push(data)
                        })
                    }

                    ws.addEventListener('message', listener)
                } catch {
                    // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
                    // in which case `cacheDataLoaded` will throw
                }
                // cacheEntryRemoved will resolve when the cache subscription is no longer active
                await cacheEntryRemoved
                // perform cleanup steps once the `cacheEntryRemoved` promise resolves
                ws.close()
            },
        }),
        getNotifications: builder.query<INotification[], void>({
            query: () => "notification/list",
            providesTags: ["Notification"],
        }),
        readNotification: builder.mutation({
            query: (data: IReadNotificationData) => ({
                url: `notification/${data.notificationId}/path/`,
                body: {
                    read: data.read
                },
                method: "PATCH",
            }),
            async onQueryStarted({notificationId}, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                    const patchResult = dispatch(
                        notificationsApi.util.updateQueryData('getMessages', undefined, (draft) => {
                            return draft.filter(notif => notif.id !== notificationId);
                        })
                    )
                } catch {}
            },
        }),
        readAllNotifications: builder.mutation({
            query: () => ({
                url: `notification/list/path/`,
                method: "PATCH",
            }),
            async onQueryStarted({notificationId}, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                    const patchResult = dispatch(
                        notificationsApi.util.updateQueryData('getMessages', undefined, () => {
                            return [];
                        })
                    )
                } catch {}
            },
        }),
        pushSubscribe: builder.mutation({
            query: (data: IPushSubscriptionData) => ({
                url: "webpush/save_information",
                method: 'POST',
                body: data,
                credentials: 'include'
            }),
        }),
    })
});

export const {
    useGetNotificationsQuery,
    useReadNotificationMutation,
    useReadAllNotificationsMutation,
    useGetMessagesQuery,
    usePushSubscribeMutation
} = notificationsApi;