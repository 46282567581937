import {Box, styled} from "@mui/material";

export default styled(Box)(() => ({
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 2,
    background: 'rgba(255, 255, 255, 0.7)'
}));
