import AddIcon from "@mui/icons-material/Add";
import {IconButton, styled} from "@mui/material";
import { MouseEventHandler } from "react";

interface IStyledIconButtonProps {
    background?: string,
    variant?: string
}


const StyledIconButton = styled(IconButton)<IStyledIconButtonProps>(({background, variant, theme}) => ({
    background: background ? background : theme.palette.primary.main,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 8px rgba(0, 0, 0, 0.08)',
    color: variant ? variant : '#fff',
    '&:hover': {
        background: theme.palette.primary.light
    }
}))

interface IAddButtonProps {
    onClick?: MouseEventHandler<HTMLButtonElement>,
    size?: 'small' | 'medium' | 'large',
    fontSize?: 'small' | 'medium' | 'large',
    background?: string,
    color?: string,
    disabled?: boolean
}

const AddButton = ({onClick, size = 'medium', fontSize = 'medium', background, color, disabled}: IAddButtonProps) => {
    return (
        <StyledIconButton onClick={onClick} size={size} background={background} variant={color} disabled={disabled}>
            <AddIcon fontSize={fontSize}/>
        </StyledIconButton>
    );
}

export default AddButton;
