import React, {ReactNode, useEffect} from 'react';
import {usePushSubscribeMutation} from "../services/notifications";
import {regSw, subscribe} from "../utils/helpers";

const PrivateContainer = ({children}: {children: ReactNode}) => {
    const [pushSubscribe] = usePushSubscribeMutation();

    async function registerAndSubscribe () {
        const permission =  await Notification.requestPermission();
        if(permission !== 'granted') return;
        try {
            const serviceWorkerReg = await regSw ();
            if(serviceWorkerReg) {
                const data = await subscribe (serviceWorkerReg);
                if(data) {
                    pushSubscribe(data);
                }
            }
        } catch (error) {
            console.log (error);
        }
    }

    useEffect(() => {
        registerAndSubscribe();
    }, []);

    return (
        <>
            {children}
        </>
    );
};

export default PrivateContainer;