import { createSlice } from "@reduxjs/toolkit";

type AuthState = {
  name: string | null;
  token: string | null;
  isLoading: boolean;
};

const initialState: AuthState = {
  name: null,
  token: null,
  isLoading: true,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, { payload: { auth_token } }) => {
      state.token = auth_token;
      localStorage.setItem("token", auth_token);
    },
    autoLogin: (state) => {
      const token = localStorage.getItem("token");
      if (!token) {
        state.token = null;
      } else {
        state.token = token;
      }
      state.isLoading = false;
    },
    logout: (state) => {
      localStorage.removeItem("token");
      state.token = null;
    },
  },
});

export default authSlice.reducer;

export const { setCredentials, autoLogin, logout } = authSlice.actions;
