import {useState} from 'react';
import {AppBar as MuiAppBar, Avatar, Badge, IconButton, styled, Toolbar, Typography} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import GroupIcon from '@mui/icons-material/Group';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar/AppBar";
import UserSettings from './UserSettings';
import {useGetUserQuery} from "../services/auth";
import {NavLink} from "react-router-dom";
import NotificationsListModal from "./Notifications/NotificationsListModal";
import {useGetMessagesQuery, useGetNotificationsQuery} from "../services/notifications";

interface INavBarProps {
    isOpenSidebar: boolean,
    toggleDrawer: () => void,
    title?: string,
    hideDrawer?: boolean
}

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const drawerWidth = 280;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const NavBarIcon = styled(IconButton)(() => ({
    margin: '0 5px',
    color: '#fff'
}))

const NavBar = ({isOpenSidebar, toggleDrawer, title, hideDrawer}: INavBarProps) => {

    const [userSettingsOpen, setUserSettingsOpen] = useState(false);


    const [notificationsAnchorEl, setNotificationsAnchorEl] = useState<null | HTMLElement>(null);
    const {data: notifications} = useGetMessagesQuery();

    const {data: user} = useGetUserQuery();

    return (
        <AppBar position="absolute" open={hideDrawer ? false : isOpenSidebar}>
            <Toolbar
                sx={{
                    pr: '24px', // keep right padding when drawer closed
                }}
            >
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    sx={{
                        marginRight: '36px',
                        ...(isOpenSidebar && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}
                >
                    {title ? title : 'SPACE-BRIEFING'}
                </Typography>
                <NavBarIcon onClick={(e) => setNotificationsAnchorEl(e.currentTarget)}>
                    <Badge badgeContent={notifications?.length} color="error">
                        <NotificationsIcon />
                    </Badge>
                </NavBarIcon>
                <NotificationsListModal
                    onClose={() => setNotificationsAnchorEl(null)}
                    anchorEl={notificationsAnchorEl}/>
                <NavBarIcon>
                    <InsertDriveFileIcon />
                </NavBarIcon>
                <IconButton
                    onClick={(e) => setUserSettingsOpen(true)}
                >
                    <Avatar
                        src={user?.avatar}
                        alt="Cindy Baker" />
                </IconButton>
                <UserSettings open={userSettingsOpen} onClose={() => setUserSettingsOpen(false)}/>
            </Toolbar>
        </AppBar>
    );
};

export default NavBar;
