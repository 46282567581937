import PersonIcon from "@mui/icons-material/Person";
import {Box, Button, styled, TextField} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import {FieldValues, SubmitHandler, useForm} from "react-hook-form";
import {LoadingButton} from "@mui/lab";

const FieldItem = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '25px',
    'svg': {
        marginRight: '30px',
    },
    '.MuiTextField-root': {
        flexGrow: 1
    }
}));

const ButtonsWrapper = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between'
}));

export type Inputs = {
    firstName: string,
    lastName: string,
    phone: string,
}

interface IUserSettingsFieldsProps {
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    onClose: () => void,
    onSubmit: SubmitHandler<Inputs>,
    loading: boolean
}


const UserSettingsFields = ({firstName, lastName, email, phone, onClose, onSubmit, loading}: IUserSettingsFieldsProps) => {
    const {register, handleSubmit, watch, formState: {errors, isDirty}} = useForm<Inputs>({
        defaultValues: {
            firstName,
            lastName,
            phone,
        }
    });

    return (
        <>
            <FieldItem sx={{alignItems: 'flex-start'}}>
                <PersonIcon sx={{marginTop: '10px'}} color="disabled"/>
                <Box sx={{display: 'grid', gap: 1}}>
                    <TextField error={!!errors.firstName}
                               size="small"
                               helperText={errors.firstName ? "Это обязательное поле" : undefined}
                               {...register("firstName")}
                               label="Имя"/>
                    <TextField error={!!errors.lastName}
                               size="small"
                               helperText={errors.lastName ? "Это обязательное поле" : undefined}
                               {...register("lastName")}
                               label="Фамилия"/>
                </Box>
            </FieldItem>
            <FieldItem>
                <PhoneIcon color="disabled"/>
                <TextField
                           error={!!errors.phone}
                           {...register("phone")}
                           label="Номер телефона"/>
            </FieldItem>
            <ButtonsWrapper>
                <Button variant="text" onClick={onClose}>Отменить</Button>
                <LoadingButton
                    disabled={!isDirty}
                    loading={loading}
                    variant="contained"
                    onClick={handleSubmit(onSubmit)}>Сохранить</LoadingButton>
            </ButtonsWrapper>
        </>
    );
}

export default UserSettingsFields;
