import {MouseEvent} from 'react';
import {
  Box,
  BoxProps,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
} from "@mui/material";
import Layout from "../components/Layout";
import TaskItem from "../components/Tasks/TaskItem";
import { useState } from "react";
import AddButton from "../components/UI/AddButton";
import TaskIcon from "../components/UI/TaskIcon";
import ShortTaskIcon from "../components/UI/ShortTaskIcon";
import CreateShortTaskModal, {
  SaveShortTaskValues,
} from "../components/Tasks/CreateShortTaskModal";
import Modal from "../components/UI/Modal";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCreateTaskMutation,
  useRemoveTaskMutation,
  useArchiveTaskMutation,
  useAddTaskExecutorMutation,
  useGetProjectTasksQuery,
  useGetTaskPotentialExecutorsQuery,
  useUpdateTaskExecutorMutation,
  ITask,
  useAccountTaskMutation,
} from "../services/tasks";
import ProjectUsersList from "../components/ProjectUsersList";
import UsersSelect from "../components/UsersSelect";
import useTitle from "../hooks/useTitle";
import NavigationTitle from "../components/UI/NavigationTitle";
import Filters, {IFilterItems} from "../components/Filters";
import useFilteredTasks from "../hooks/useFilteredTasks";
import SortIcon from '@mui/icons-material/Sort';
import TasksListSkeleton from "../components/Tasks/TasksListSkeleton";
import {useDispatch} from "react-redux";
import {appAlert} from "../features/appSlice";
import {useGetProjectQuery} from "../services/projects";
import TableContainer from '@mui/material/TableContainer';
import TaskItemSkeleton from "../components/Tasks/TaskItemSkeleton";

export const HeadingTableCell = styled(TableCell)(() => ({
  padding: "10px",
  color: "#fff",
}));

export const TableHeading = styled(TableRow)(({ theme }) => ({
  background: theme.palette.grey["500"],
}));

const AddButtonWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  position: "fixed",
  bottom: '40px',
  left: 0,
  width: '100%'
}));

interface ICreateTaskItemProps extends BoxProps {
  open?: boolean;
}

const CreateTaskItem = styled(Box)<ICreateTaskItemProps>(({ open }) => ({
  borderRadius: "8px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.24)",
  background: "#fff",
  padding: "10px",
  minWidth: "280px",
  textAlign: "center",
  position: "relative",
  cursor: "pointer",
  transition: "all .2s linear",

  opacity: open ? 1 : 0,
  "&:first-of-type": {
    transform: !open ? "translate(-15px, -15px)" : "translate(0, 0)",
    ".MuiBox-root": {
      right: "-12px",
    },
  },
  "&:last-child": {
    transform: !open ? "translate(15px, -15px)" : "translate(0, 0)",
    ".MuiBox-root": {
      left: "-12px",
    },
  },
}));

const CreateTaskIcon = styled(Box)(({ theme }) => ({
  width: "28px",
  height: "28px",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: theme.palette.primary.main,
  position: "absolute",
  top: "10px",
}));

const ProjectTasks = () => {
  useTitle("Tasks");
  const dispatch = useDispatch();

  const [isCreateTaskMenuOpen, setIsCreateTaskMenuOpen] = useState(false);
  const [taskMenuAnchor, setTaskMenuAnchor] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const taskMenuOpen = Boolean(taskMenuAnchor);
  const [currentTask, setCurrentTask] = useState<null | ITask>(null);
  const [error, setError] = useState<string | null>(null);

  const [shortCreateTaskModalOpen, setCreateShortTaskModalOpen] = useState(false);

  const openTaskMenu = (e: MouseEvent<HTMLElement>, task: ITask) => {
    e.preventDefault();
    setTaskMenuAnchor(
      taskMenuAnchor === null
        ? {
            mouseX: e.clientX + 2,
            mouseY: e.clientY - 6,
          }
        : null
    );
    setCurrentTask(task);
  };


  const { id: projectId } = useParams();
  const [createTask, { isLoading: isCreating }] = useCreateTaskMutation();

  const createTaskHandler = (values: SaveShortTaskValues) => {
    if (projectId) {
      createTask({
        project: projectId,
        title: values.title,
        linkToResource: values.linkToResource,
        dateTo: values.dateTo?.toISOString()
      })
        .unwrap()
        .then((task) => {
          if(futureExecutor) {
            addTaskExecutor({
              taskId: task.id,
              userId: futureExecutor,
            });
            setFutureExecutor(null);
          }
        })
        .catch((e) => {
          setError(e.data.message);
        })
          .finally(() => {
            setCreateShortTaskModalOpen(false);
          });
    }
  };

  const [futureExecutor, setFutureExecutor] = useState<string | null>(null);

  const createTaskWithExecutor = (userId: string) => {
    setFutureExecutor(userId);
    setCreateShortTaskModalOpen(true);
  }

  const navigate = useNavigate();

  const { data: tasks, isLoading, isFetching } = useGetProjectTasksQuery(projectId as string);

  const [removeTask] = useRemoveTaskMutation();

  const removeTaskHandler = () => {
    if (currentTask) {
      removeTask(currentTask.id)
        .unwrap()
        .then(() => {
          setTaskMenuAnchor(null);
        })
          .catch((err) => {
            dispatch(appAlert({title: err.data.detail, status: 'error'}))
          });
    }
  };

  const [archiveTask] = useArchiveTaskMutation();

  const archiveHandler = (isPublished: boolean) => {
    if (currentTask) {
      setTaskMenuAnchor(null);
      archiveTask({
        taskId: currentTask.id,
        isPublished,
      })
          .unwrap()
          .catch((err) => {
            dispatch(appAlert({title: err.data.detail, status: 'error'}))
          });
    }
  };

  const [accountTask] = useAccountTaskMutation();

  const accountHandler = (isAccounted: boolean) => {
    if (currentTask) {
      setTaskMenuAnchor(null);
      accountTask({
        taskId: currentTask.id,
        isAccounted,
      })
          .unwrap()
          .catch((err) => {
            dispatch(appAlert({title: err.data.detail, status: 'error'}))
          });
    }
  };

  const [selectedTask, setSelectedTask] = useState<null | number>(null);
  const [selectedUser, setSelectedUser] = useState("");
  const {data: potentialExecutors} = useGetTaskPotentialExecutorsQuery(selectedTask as number, {skip: !selectedTask});


  const [addTaskExecutor] = useAddTaskExecutorMutation();

  const addExecutorHandler = () => {
    setSelectedTask(null);
    setSelectedUser('');
    if (selectedTask && selectedUser) {
      addTaskExecutor({
        taskId: selectedTask,
        userId: selectedUser,
      }) .unwrap()
          .catch((err) => {
            dispatch(appAlert({title: err.data.detail, status: 'error'}))
          });
    }
  };

  const [updateExecutor] = useUpdateTaskExecutorMutation();

  const updateCompleteHandler = (executorId: number, isCompleted: boolean) => {
    updateExecutor({
      executorId, isCompleted
    }).unwrap()
        .catch((err) => {
          dispatch(appAlert({title: err.data.detail, status: 'error'}))
        });
  };

  const changeTimerHandler = (executorId: number, isPlay: boolean) => {
    updateExecutor({
      executorId, isPlay
    }).unwrap()
        .catch((err) => {
      dispatch(appAlert({title: err.data.detail, status: 'error'}))
    });
  }

  const changeTimerManualHandler = (executorId: number, seconds: number) => {
    updateExecutor({
      executorId,
      totalTime: seconds
    }).unwrap()
        .catch((err) => {
          dispatch(appAlert({title: err.data.detail, status: 'error'}))
        });
  }

  const [filters, setFilters] = useState<IFilterItems>({
    iExecutor: {
      label: 'Я исполнитель',
      selected: false
    },
    iCreator: {
      label: 'Я создатель',
      selected: false
    },
    iManager: {
      label: 'Я мененджер',
      selected: false
    },
    completed: {
      label: 'Завершенные',
      selected: false
    },
    accounted: {
      label: 'Оплаченные',
      selected: false
    },
  });

  const filteredTasks = useFilteredTasks(filters, tasks);

  const {data: project} = useGetProjectQuery(projectId as string, {skip: !projectId});

  const projectTitle = project ? project.title : '';

  return (
    <Layout
        title={`Таски для ${projectTitle}`}
        heading={<Filters navigationButtonText="TO ALL TASKS" navigationLink="/tasks" filters={filters} onChange={setFilters}/>}
      navigation={<NavigationTitle title="Участники проекта"/>}
      menu={
        <ProjectUsersList
            onCreateTask={createTaskWithExecutor}
            projectId={projectId}
        />
      }
    >
  {/*//     <Modal*/}
  {/*//       title="Исполнитель добавлен"*/}
  {/*//       cancelButtonText="ОК"*/}
  {/*//       open={isExecutorAddedModalOpen}*/}
  {/*//       onClose={() => setIsExecutorAddedModalOpen(false)}*/}
  {/*//     />*/}
       <Modal
         title="Добавить испонителя таска"
         submitButtonText="ДОБАВИТЬ"
         cancelButtonText="ОТМЕНИТЬ"
         open={!!selectedTask}
         onClose={() => setSelectedTask(null)}
         onSubmit={addExecutorHandler}
       >
         <UsersSelect
           label="Исполнитель таска"
           users={potentialExecutors}
           onChange={(e) => {
             const {
               target: { value },
             } = e;
             setSelectedUser(value);
           }}
           value={selectedUser}
         />
       </Modal>
       <Modal
         open={shortCreateTaskModalOpen}
         onClose={() => setCreateShortTaskModalOpen(false)}
       >
         <Box
           sx={{
             position: "relative",
           }}
         >
           {isCreating && (
             <Box
               sx={{
                 position: "absolute",
                 left: "50%",
                 top: "50%",
                 transform: "translate(-50%, -50%)",
               }}
             >
               loading...
             </Box>
           )}
           <Box
             sx={{
               opacity: isCreating ? 0.3 : 1,
             }}
           >
             <CreateShortTaskModal
               onSave={createTaskHandler}
               error={error}
               onCancel={() => setCreateShortTaskModalOpen(false)}
             />
           </Box>
         </Box>
       </Modal>
        <Menu
         open={taskMenuOpen}
         anchorReference="anchorPosition"
         anchorPosition={
           taskMenuAnchor !== null
             ? { top: taskMenuAnchor.mouseY, left: taskMenuAnchor.mouseX }
             : undefined
         }
         onClose={() => setTaskMenuAnchor(null)}
       >
          {
              currentTask && (
                  currentTask.is_published ?
                  <MenuItem onClick={() => archiveHandler(false)}>Завершить</MenuItem> :
                  <MenuItem onClick={() => archiveHandler(true)}>Активировать</MenuItem>
              )
          }
          {
            currentTask && !currentTask.is_published && (
                currentTask.is_accounted ?
                    <MenuItem onClick={() => accountHandler(false)}>Отменить статус "оплачен"</MenuItem> :
                    <MenuItem onClick={() => accountHandler(true)}>Оплачен</MenuItem>
              )
          }
         <MenuItem onClick={removeTaskHandler}>Удалить</MenuItem>
       </Menu>
      <TableContainer>
        <Table sx={{mb: 5, minWidth: '1290px'}}>
          <TableHead>
            <TableHeading>
              <HeadingTableCell>ID</HeadingTableCell>
              <HeadingTableCell>Краткое описание</HeadingTableCell>
              <HeadingTableCell>Выполнить до</HeadingTableCell>
              <HeadingTableCell>Время / статус / исполнитель</HeadingTableCell>
              <HeadingTableCell>Создатель</HeadingTableCell>
              <HeadingTableCell>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                  <SortIcon/>
                </Box>
              </HeadingTableCell>
            </TableHeading>
          </TableHead>
          <TableBody>
            {isLoading || isFetching
                ? <TasksListSkeleton/>
                : filteredTasks.map((task, idx) => (
                    task.loading ? <TaskItemSkeleton/> :
                    <TaskItem
                        taskId={task.id}
                        completed={!task.is_published}
                        accounted={task.is_accounted}
                        onClick={() => {
                          navigate(`/projects/${projectId}/tasks/${task.id}`);
                        }}
                        href={`/projects/${projectId}/tasks/${task.id}`}
                        key={task.id}
                        title={task.title}
                        type="FULL"
                        executors={task.executors}
                        creatorName={task.creator.first_name ? task.creator.first_name : task.creator.email}
                        creatorId={task.creator.id}
                        creatorAvatar={task.creator.avatar}
                        onAdd={() => setSelectedTask(task.id)}
                        completeDate={task.time_to_completion}
                        onChangeTimer={changeTimerHandler}
                        onChangeTimerManual={changeTimerManualHandler}
                        onExecutorComplete={updateCompleteHandler}
                        onContextMenu={(e) => openTaskMenu(e, task)}
                    />
                ))}
          </TableBody>
        </Table>
      </TableContainer>

       <AddButtonWrapper>
         <AddButton
           onClick={() => setCreateShortTaskModalOpen(true)}
           size="large"
         />
       </AddButtonWrapper>
    </Layout>
  );
};

export default ProjectTasks;
