import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  styled,
  TextField,
} from "@mui/material";
import DnDArea from "../UI/DnDArea";
import Modal from "../UI/Modal";
import { useState } from "react";
import {useAddProjectImageMutation, useAddProjectMutation} from "../../services/projects";
import { useForm, SubmitHandler } from "react-hook-form";
import OverLay from "../UI/OverLay";
import LinkIcon from "@mui/icons-material/Link";
import { appAlert } from "../../features/appSlice";
import { useDispatch } from "react-redux";

const ModalFooter = styled(Box)(() => ({
  marginTop: "30px",
  display: "flex",
  justifyContent: "space-between",
}));

const ErrorLabel = styled("p")(({ theme }) => ({
  fontSize: "12px",
  color: theme.palette.error.main,
}));

interface IAddProjectModalProps {
  isOpen: boolean;
  onClose: () => void;
}

type Inputs = {
  projectName: string;
};

const AddProjectModal = ({ isOpen, onClose }: IAddProjectModalProps) => {
  const [projectLogo, setProjectLogo] = useState<null | File>(null);
  const [projectLogoError, setProjectLogoError] = useState<null | string>(null);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm<Inputs>();

  const dispatch = useDispatch();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (data.projectName) {
      try {
        const project = await addProject({
          name: data.projectName,
        }).unwrap();
        if(projectLogo) {
          await addProjectImage({id: project.id, image: projectLogo}).unwrap();
        }
      } catch (e) {
        //setError(e.data.message);
      }
      onClose();
      dispatch(appAlert({ title: "Проект создан" }))
    }
  };

  const [addProject, { isLoading: isAddProjectLoading }] = useAddProjectMutation();
  const [addProjectImage, { isLoading: isAddImageLoading }] = useAddProjectImageMutation();
  const [error, setError] = useState(null);
  const addLogoHandler = (file: File) => {
    setProjectLogoError(null);
    setProjectLogo(file);
  };

  return (
    <Modal open={isOpen} title="Новый проект" onClose={onClose}>
      <Box
        style={{
          display: "grid",
          position: "relative",
          flexDirection: "column",
          gap: 24,
        }}
      >
        {(isAddProjectLoading || isAddImageLoading) && <OverLay />}
        <p>{error}</p>
        <TextField
          error={!!errors.projectName}
          helperText={errors.projectName ? "Это обязательное поле" : undefined}
          label="Название"
          {...register("projectName", { required: true })}
        />
        <Box>
          <DnDArea
            onAdd={addLogoHandler}
            label="Перетащите логотип проекта сюда, чтобы добавить"
          />
          {projectLogoError && <ErrorLabel>{projectLogoError}</ErrorLabel>}
        </Box>
        <ModalFooter>
          <Button variant="text" onClick={onClose}>
            Отменить
          </Button>
          <Button
            disabled={false}
            variant="contained"
            onClick={() => {
              handleSubmit(onSubmit)();
            }}
          >
            Сохранить
          </Button>
        </ModalFooter>
      </Box>
    </Modal>
  );
};

export default AddProjectModal;
