import EditorJS, {OutputData, ToolConstructable, ToolSettings} from '@editorjs/editorjs';
import Header from '@editorjs/header';
import NestedList from '@editorjs/nested-list';
import Delimiter from '@editorjs/delimiter';
import Checklist from '@editorjs/checklist';
import Table from '@editorjs/table';
import Alert from 'editorjs-alert';
import ImageTool from '@editorjs/image';
import ColorPlugin from 'editorjs-text-color-plugin';
import Strikethrough from '@sotaproject/strikethrough';
import CodeTool from '@editorjs/code';
import {useEffect} from "react";
import axios from "axios";
import {useAppSelector} from "../../store";

interface ITextEditorProps {
    onChange?: (data: OutputData) => void,
    data?: OutputData,
    readOnly?: boolean,
    additionId?: number,
    uploadImagePath?: string,
    className?: string
}

const TextEditor = ({onChange, data, readOnly, additionId, uploadImagePath, className}: ITextEditorProps) => {
    const id = Math.random();
    const { token } = useAppSelector((state) => state.auth);

    const tools: {[p: string]: ToolConstructable | ToolSettings<any>} = {
        header: Header,
        list: {
            class: NestedList,
            inlineToolbar: true,
            config: {
                defaultStyle: 'unordered'
            },
        },
        delimiter: Delimiter,
        checklist: {
            class: Checklist,
            inlineToolbar: true,
        },
        alert: Alert,
        Color: {
            class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
            config: {
                colorCollections: ['#EC7878','#9C27B0','#673AB7','#3F51B5','#0070FF','#03A9F4','#00BCD4','#4CAF50','#8BC34A','#CDDC39', '#FFF'],
                defaultColor: '#FF1300',
                type: 'text',
                customPicker: true // add a button to allow selecting any colour
            }
        },
        Marker: {
            class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
            config: {
                defaultColor: '#FFBF00',
                type: 'marker',
                icon: `<svg fill="#000000" height="200px" width="200px" version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M17.6,6L6.9,16.7c-0.2,0.2-0.3,0.4-0.3,0.6L6,23.9c0,0.3,0.1,0.6,0.3,0.8C6.5,24.9,6.7,25,7,25c0,0,0.1,0,0.1,0l6.6-0.6 c0.2,0,0.5-0.1,0.6-0.3L25,13.4L17.6,6z"></path> <path d="M26.4,12l1.4-1.4c1.2-1.2,1.1-3.1-0.1-4.3l-3-3c-0.6-0.6-1.3-0.9-2.2-0.9c-0.8,0-1.6,0.3-2.2,0.9L19,4.6L26.4,12z"></path> </g> <g> <path d="M28,29H4c-0.6,0-1-0.4-1-1s0.4-1,1-1h24c0.6,0,1,0.4,1,1S28.6,29,28,29z"></path> </g> </g></svg>`
            }
        },
        table: {
            class: Table,
        },
        strikethrough: Strikethrough,
        code: CodeTool,
    }

    if(additionId && uploadImagePath) {
        tools.image = {
            class: ImageTool,
                config: {
                uploader: {
                    uploadByFile: (file: File) => {
                        let formData = new FormData();
                        formData.append("image", file);
                        return axios.post(`${process.env.REACT_APP_API_URL}project/task/${uploadImagePath}/${additionId}/image/post/`, formData, {
                            headers: {
                                'Authorization': `Token ${token}`
                            }
                        }).then((res ) => {
                            return {
                                success: 1,
                                file: {
                                    url: res.data.image,
                                }
                            }
                        })
                    }
                }
            }
        }
    }

    useEffect(() => {
        const editor = new EditorJS({
            data,
            readOnly,
            tools,
            holder: 'editor'+id,
            async onChange(api, event) {
                const content = await editor.save();

                if(onChange) {
                    onChange(content);
                }
            }
        });

        // editor.saver.save().then((outputData) => {
        //
        //     if(onChange) {
        //         onChange(outputData);
        //     }
        // })

        return () => {
            editor.isReady.then(() => {
                editor.destroy();
            })
                .catch(e => console.error('ERROR editor cleanup', e));
        }
    }, [])


    return (
        <div className={className} id={"editor"+id}/>
    );
}

export default TextEditor;
