import {ReactNode} from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {Button, styled, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    outline: 'none',
    p: 4,
};

const TitleStyled = styled(Typography)(() => ({
    fontWeight: 500,
    fontSize: '20px'
}))

const Footer = styled(Box)(() => ({
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between'
}));

interface IModalProps {
    open: boolean,
    onClose: () => void,
    children?: ReactNode,
    title?: string,
    submitButtonText?: string,
    cancelButtonText?: string,
    onSubmit?: () => void,
    loading?: boolean
}

const AppModal = ({open, onClose, children, title, submitButtonText, cancelButtonText, onSubmit, loading}: IModalProps) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Box sx={style}>
                {
                    title &&
                    <Box
                        mb={2}
                    >
                        <TitleStyled>
                            {title}
                        </TitleStyled>
                    </Box>
                }
                {children}
                {(submitButtonText || cancelButtonText) &&
                <Footer>
                    {!submitButtonText && <div></div>}
                    {
                        cancelButtonText && <Button variant="text" onClick={onClose}>{cancelButtonText}</Button>
                    }
                    {
                        submitButtonText &&
                        <LoadingButton
                            loading={loading}
                            variant="contained" onClick={onSubmit}>{submitButtonText}</LoadingButton>
                    }
                </Footer>
                }
            </Box>
        </Modal>
    );
}

export default AppModal;
