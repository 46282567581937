import {Box, CircularProgress, Paper, styled, Typography} from "@mui/material";
import TextEditor from "../Blocks/TextEditor";
import { OutputData } from '@editorjs/editorjs';
import {useEffect, useState} from "react";
import AppPaper from "../UI/AppPaper";
import AdditionControls from "./AdditionControls";
import {
    IAddition,
    useAddAdditionCommentMutation,
    useRemoveTaskBoardMutation,
    useUpdateTaskBoardMutation
} from "../../services/tasks";
import Modal from "../UI/Modal";
import useDebounce from "../../hooks/useDebounce";
import AdditionComment from "./AdditionComment";
import generateEditorOutputData from "../../utils/generateEditorOutputData";
import {useDispatch} from "react-redux";
import {appAlert} from "../../features/appSlice";


const UploadCheck = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'flex-end'
}));

interface IAdditionProps {
    iExecutor?: boolean,
    addition: IAddition,
    index: number
}

const Addition = ({addition, index, iExecutor}: IAdditionProps) => {

    const dispatch = useDispatch();

    const [content, setContent] = useState<OutputData | undefined>(addition.addition);

    const debouncedContent = useDebounce(content, 500);

    const [updateTaskBoard, {isLoading}] = useUpdateTaskBoardMutation();

    useEffect(() => {
        if(debouncedContent) {
            if(JSON.stringify(debouncedContent) !== JSON.stringify(addition.addition)) {
                updateTaskBoard({
                    id: addition.id,
                    content: debouncedContent
                });
            }
        }
    }, [debouncedContent]);


    const [removeTaskBoard, {isLoading: isTaskBoardRemoving}] = useRemoveTaskBoardMutation();
    const deleteHandler = () => {
        removeTaskBoard(addition.id).unwrap()
            .catch((err) => {
                dispatch(appAlert({title: err.data.detail, status: 'error'}));
            });
    }

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);


    const [addAdditionComment, {isLoading: isAddCommentLoading}] = useAddAdditionCommentMutation();
    const commentHandler = () => {
        addAdditionComment({
            additionId: addition.id,
            content: generateEditorOutputData()
        }).unwrap()
            .catch((err) => {
                dispatch(appAlert({title: err.data.detail, status: 'error'}));
            });
    }

    return (
        <AppPaper sx={{marginTop: 4}} label={`${index + 1}. Дополнение`}>
            <Modal
                open={isDeleteModalOpen}
                onClose={() => {
                    setIsDeleteModalOpen(false);
                }}
                loading={isTaskBoardRemoving}
                title="Are you sure ?"
                cancelButtonText="Cancel"
                submitButtonText="Delete"
                onSubmit={deleteHandler}
            />
            <TextEditor
                additionId={addition.id}
                uploadImagePath="addition"
                data={content}
                onChange={setContent}
                className="parentEditor"
            />
            <AdditionControls
                isLoading={isAddCommentLoading}
                onComment={(addition.comments.length > 1 || !iExecutor) ? undefined : commentHandler}
                onDelete={() => {
                    setIsDeleteModalOpen(true);
                }}
                creator={addition.creator}
                updater={addition.updater}
                createdAt={addition.time_create}
                updatedAt={addition.time_update}/>
            {
                addition.comments.length > 1 && <AdditionComment iExecutor={iExecutor} comment={addition.comments[0]}/>
            }
            {
                isLoading && (
                    <CircularProgress
                        sx={{
                            position: 'absolute',
                            right: 5,
                            top: 5,
                            zIndex: 99
                        }}
                        color="primary"
                        size={25}
                    />
                )
            }
        </AppPaper>
    )
}

export default Addition;