import {MouseEvent, MouseEventHandler} from 'react';
import {styled, Avatar, ListItem, ListItemAvatar, ListItemText, Box, Button, IconButton} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface IUserListItemProps {
    isOwner: boolean,
    name: string,
    role: string,
    avatar?: string,
    onClick?: MouseEventHandler<HTMLButtonElement>,
    onAvatarClick?: (event: MouseEvent<HTMLButtonElement>) => void
}

const StyledListItem = styled(ListItem)(({theme}) => ({
    alignItems: 'center',
    borderBottom: '1px solid rgba(38, 50, 56, 0.16)',
    [theme.breakpoints.down('sm')]: {
        paddingLeft: '7px'
    }
}));

const UserListItem = ({isOwner, name, role, avatar, onClick, onAvatarClick}: IUserListItemProps) => {
    const theme = useTheme()
    return (
        <StyledListItem>
            <ListItemAvatar sx={{ml: -1, mr: 1}}>
                <IconButton onClick={onAvatarClick}>
                    <Avatar alt={name} src={avatar} />
                </IconButton>
            </ListItemAvatar>
            <ListItemText
                primary={name}
                secondary={role}
            />
            {
                onClick &&
                <IconButton
                    onClick={onClick}
                    aria-label="more"
                    id="long-button"
                    aria-haspopup="true"
                >
                    <MoreVertIcon />
                </IconButton>
            }
        </StyledListItem>
    );
}

export default UserListItem;
