import {Box, Button, Checkbox, FormControlLabel, styled} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";


const Wrapper = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 12,
    paddingBottom: 12,
}));

const FiltersWrapper = styled(Box)(() => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
}));

const NavigationButton = styled(Button)(() => ({
    minWidth: 210
}));

const FiltersCheckbox = styled(Checkbox)(() => ({
    color: '#DE05D3'
}));

interface IFiltersLabelProps {
    checked?: boolean
}

const FiltersLabel = styled(FormControlLabel)<IFiltersLabelProps>(({theme, checked}) => ({
    color: checked ? '#DE05D3' : theme.palette.primary.main
}));

export interface IFilterItem {
    label: string,
    selected: boolean
}

export interface IFilterItems {
    [key:string]: IFilterItem
}

interface IFiltersProps {
    filters: IFilterItems,
    onChange: (filters: IFilterItems) => void,
    navigationButtonText: string,
    navigationLink: string,
}
const Filters = ({filters, onChange, navigationButtonText, navigationLink}: IFiltersProps) => {

    const changeHandler = (filter: keyof IFilterItems) => {
        const newFilters = {...filters};
        newFilters[filter].selected = !newFilters[filter].selected;
        onChange(newFilters);
    }

    const navigate = useNavigate();

    return (
        <Wrapper>
            <Link to={navigationLink}>
                <Button
                    sx={{
                        minWidth: 210
                    }}
                    variant="outlined">
                    {navigationButtonText}
                </Button>
            </Link>
            <FiltersWrapper>
                {
                    Object.keys(filters).map((filter, idx) => (
                        <FiltersLabel
                            key={filter}
                            checked={filters[filter].selected}
                            labelPlacement="start"
                            control={
                            <FiltersCheckbox
                                onChange={() => changeHandler(filter)}
                                checked={filters[filter].selected}
                            />}
                            label={filters[filter].label}
                        />
                    ))
                }
            </FiltersWrapper>
        </Wrapper>
    )
}

export default Filters;