export default function secondsToHms(seconds: number) {
  if (!seconds) return '00m 00s'

  let duration = seconds
  let hours: number|string = duration / 3600
  duration = duration % 3600

  let min: number|string = parseInt((duration / 60).toString())
  duration = duration % 60

  let sec: number|string = parseInt(duration.toString())

  if (sec < 10) {
    sec = `0${sec}`
  }
  if (min < 10) {
    min = `0${min}`
  }

  if (parseInt(hours.toString(), 10) > 0) {
    return `${parseInt(hours.toString(), 10)}:${min}:${sec}`
  } else if (min === 0) {
    return `00:00:${sec}`
  } else {
    return `00:${min}:${sec}`
  }
}