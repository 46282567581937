import { TextField, Box, Button, styled } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm } from "react-hook-form";

const Wrapper = styled("form")(() => ({
  display: "grid",
  gap: 20,
  minWidth: "350px",
}));

export type LoginFormValues = {
  name: string;
  password: string;
};

interface ILoginFormProps {
  onSubmit: (values: LoginFormValues) => void;
  loading: boolean;
}

type Inputs = {
  name: string;
  password: string;
};

const LoginForm = ({ onSubmit, loading }: ILoginFormProps) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      name: "",
      password: "",
    },
  });

  return (
    <Wrapper>
      <TextField
        error={!!errors.name}
        helperText={errors.name ? "Это обязательное поле" : undefined}
        {...register("name", { required: true })}
        label="Логин"
      />
      <TextField
        type="password"
        error={!!errors.password}
        helperText={errors.password ? "Это обязательное поле" : undefined}
        {...register("password", { required: true })}
        label="Пароль"
      />
      <LoadingButton
        onClick={handleSubmit(onSubmit)}
        loading={loading}
        variant="contained"
      >
        Войти
      </LoadingButton>
    </Wrapper>
  );
};

export default LoginForm;
