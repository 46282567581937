import { ReactNode, useEffect, useState } from "react";
import {
  Typography,
  Box,
  styled,
  TextField,
  Button,
  Skeleton,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import Layout from "../components/Layout";
import ProjectCard from "../components/Projects/ProjectCard";
import { useNavigate } from "react-router-dom";
import {
  IGuestProject, useAddProjectImageMutation,
  useAddProjectMutation,
  useChangeGuestProjectPasswordMutation,
  useGetGuestProjectQuery,
  useGetProjectsQuery,
  useRemoveProjectMutation,
  useRenameProjectMutation, useUpdateProjectPublishedMutation,
} from "../services/projects";
import AddButton from "../components/UI/AddButton";
import Modal from "../components/UI/Modal";
import AddProjectModal from "../components/Projects/AddProjectModal";
import useTitle from "../hooks/useTitle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OverLay from "../components/UI/OverLay";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { appAlert } from "../features/appSlice";
import DnDArea from "../components/UI/DnDArea";
import ProjectsTabs from "../components/Projects/ProjectsTabs";
import useFilteredProjects from "../hooks/useFilteredProjects";
import UsersList from "../components/UsersList";
import NavigationTitle from "../components/UI/NavigationTitle";
import Filters, {IFilterItems} from "../components/Filters";
import useProjects from "../hooks/useProjects";

interface TitleProps {
  children?: ReactNode;
}

const Title = (props: TitleProps) => {
  return (
    <Typography component="h2" variant="h6" mt={2} color="primary" gutterBottom>
      {props.children}
    </Typography>
  );
};

const ProjectsGrid = styled(Box)(() => ({
  display: "flex",
  flexWrap: 'wrap',
  rowGap: "30px",
  columnGap: "30px",
}));

const AddButtonWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  marginTop: 66,
}));

type Inputs = {
  password: string;
  confirmPassword: string;
};

const Projects = () => {
  useTitle("Projects");
  const navigate = useNavigate();

  const { data, isLoading } = useGetProjectsQuery();
  const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] =
    useState(false);

  const [removeProject] = useRemoveProjectMutation();
  const [renameProject] = useRenameProjectMutation();


  const [isGuestModalOpen, setIsGuestModalOpen] = useState(false);
  const [activeProject, setActiveProject] = useState<string | null>(null);

  const dispatch = useDispatch();

  const deleteHandler = (id: string) => {
    removeProject(id)
      .unwrap()
      .then(() => {
        dispatch(appAlert({ title: "Проект удален" }));
      })
        .catch((err) => {
          dispatch(appAlert({ title: err.data.detail, status: 'error' }));
        });
  };

  const renameHandler = (id: string, name: string) => {
    renameProject({
      id,
      name,
    })
      .unwrap()
      .then(() => {
        dispatch(appAlert({ title: "Проект переименован" }));
      })
        .catch((err) => {
          dispatch(appAlert({ title: err.data.detail, status: 'error' }));
        });
  };

  const { data: guestProject, isLoading: isLoadingGuest } =
    useGetGuestProjectQuery(activeProject as string, { skip: !activeProject });

  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>();

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchor);

  const [changePassword, { isLoading: isChangingPassword }] =
    useChangeGuestProjectPasswordMutation();

  const onSubmitPassword: SubmitHandler<Inputs> = (values) => {
    if (activeProject) {
      setChangePasswordModalOpen(false);
      setMenuAnchor(null);
      changePassword({
        project_id: activeProject,
        new_pass: values.password,
      });
    }
  };


    const [projectToUpdateImage, setProjectToUpdateImage] =
        useState<null | string>(null);
    const [projectLogo, setProjectLogo] = useState<null | File>(null);
    const [addProjectImage, { isLoading: isAddImageLoading }] = useAddProjectImageMutation();

    const addLogoHandler = (file: File) => {
        setProjectLogo(file);
    }

    const updateImageHandler = () => {
        if(projectLogo && projectToUpdateImage) {
            addProjectImage({id: projectToUpdateImage, image: projectLogo})
                .unwrap()
                .then(() => {
                    setProjectToUpdateImage(null);
                });
        }
    }


    const [updateProjectPublished] = useUpdateProjectPublishedMutation();

    const archiveHandler = (projectId: string, isPublished: boolean) => {
      updateProjectPublished({
        projectId, isPublished
      }).unwrap()
          .catch((err) => {
            dispatch(appAlert({title: err.data.detail, status: 'error'}));
          });
    }


    const [filters, setFilters] = useState<IFilterItems>({
      completed: {
        label: 'Завершенные',
        selected: false
      },
      active: {
        label: 'Активные',
        selected: true
      }
    });


    const filteredProjects = useFilteredProjects(filters, data);

    const myProjects = useProjects(filteredProjects, true);
    const otherProjects = useProjects(filteredProjects, false);

  return (
    <Layout
        title="ВСЕ ПРОЕКТЫ"
        navigation={<NavigationTitle title="Моя команда"/>}
        menu={<UsersList/>}
        heading={<Filters navigationButtonText="TO ALL TASKS" navigationLink="/tasks" filters={filters} onChange={setFilters}/>}
    >
      <Menu
        anchorEl={menuAnchor}
        open={menuOpen}
        onClose={() => setMenuAnchor(null)}
      >
        <MenuItem onClick={() => setChangePasswordModalOpen(true)}>
          Сменить пароль
        </MenuItem>
      </Menu>
      <Modal
        title="Сменить пароль"
        open={changePasswordModalOpen}
        onClose={() => setChangePasswordModalOpen(false)}
        onSubmit={handleSubmit(onSubmitPassword)}
        cancelButtonText="ОТМЕНИТЬ"
        submitButtonText="СОХРАНИТЬ"
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <TextField
            error={!!errors.password}
            helperText={errors.password ? errors.password.message : undefined}
            {...register("password", {
              required: { value: true, message: "Это обязательное поле" },
              minLength: { value: 6, message: "Не меньше 6 символов" },
              maxLength: { value: 20, message: "Не больше 20 символов" },
            })}
            label="Пароль"
          />
          <TextField
            error={!!errors.confirmPassword}
            helperText={
              errors.confirmPassword
                ? errors.confirmPassword.message
                : undefined
            }
            {...register("confirmPassword", {
              required: { value: true, message: "Это обязательное поле" },
              validate: (val: string) => {
                if (watch("password") != val) {
                  return "Пароли должны совпадать";
                }
              },
            })}
            label="Повторите пароль"
          />
        </Box>
      </Modal>
        <Modal
            title="Обновить изображение"
            open={!!projectToUpdateImage}
            onClose={() => setProjectToUpdateImage(null)}
            cancelButtonText="Отменить"
            submitButtonText="Сохранить"
            onSubmit={updateImageHandler}
            loading={isAddImageLoading}
        >
            <DnDArea
                onAdd={addLogoHandler}
                label="Перетащите логотип проекта сюда, чтобы добавить"
            />
        </Modal>
      <Modal
        title="Доступы - client"
        open={isGuestModalOpen}
        onClose={() => setIsGuestModalOpen(false)}
        cancelButtonText="ОК"
      >
        {(isLoadingGuest || isChangingPassword) && <OverLay />}
        <IconButton
          onClick={(e) => setMenuAnchor(e.currentTarget)}
          sx={{
            position: "absolute",
            right: 10,
            top: 25,
          }}
        >
          <MoreVertIcon color="primary" />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            gap: 2,
          }}
        >
          <div>
            <p>Имя</p>
            <p>Ссылка</p>
          </div>
          <div>
            <p>{guestProject?.name}</p>
            <a
              href={`${process.env.REACT_APP_API_URL}projects/${guestProject?.slug}/chapters`}
            >{`${process.env.REACT_APP_API_URL}projects/${guestProject?.slug}/chapters`}</a>
          </div>
        </Box>
      </Modal>
      <Title>Мои проекты</Title>
      <ProjectsGrid>
        {isLoading
          ? [0, 1, 2].map((item) => (
              <Skeleton
                key={item}
                variant="rectangular"
                width={209}
                height={180}
              />
            ))
          : myProjects.map(({ id, title, image, is_published }) => (
              <ProjectCard
                id={id}
                key={id}
                isPublished={is_published}
                onArchive={() => archiveHandler(id, !is_published)}
                name={title}
                image={image}
                onDelete={() => deleteHandler(id)}
                onRename={(name: string) => renameHandler(id, name)}
                onGuest={() => {
                  setIsGuestModalOpen(true);
                  setActiveProject(id);
                }}
                url={`/projects/${id}/tasks`}
                onUpdateImage={() => setProjectToUpdateImage(id)}
                onClick={() => navigate(`/projects/${id}/tasks`)}
              />
            ))}
      </ProjectsGrid>
      <Title>Проекты, в которых я учавствую</Title>
      <ProjectsGrid>
        {isLoading
            ? [0, 1, 2].map((item) => (
                <Skeleton
                    key={item}
                    variant="rectangular"
                    width={209}
                    height={180}
                />
            ))
            : otherProjects.map(({ id, title, image, is_published }) => (
                <ProjectCard
                    id={id}
                    key={id}
                    isPublished={is_published}
                    onArchive={() => archiveHandler(id, !is_published)}
                    name={title}
                    image={image}
                    onDelete={() => deleteHandler(id)}
                    onRename={(name: string) => renameHandler(id, name)}
                    onGuest={() => {
                      setIsGuestModalOpen(true);
                      setActiveProject(id);
                    }}
                    onUpdateImage={() => setProjectToUpdateImage(id)}
                    url={`/projects/${id}/tasks`}
                    onClick={() => navigate(`/projects/${id}/tasks`)}
                />
            ))}
      </ProjectsGrid>
      <AddProjectModal
        isOpen={isCreateProjectModalOpen}
        onClose={() => setIsCreateProjectModalOpen(false)}
      />
      <AddButtonWrapper>
        <AddButton
          onClick={() => setIsCreateProjectModalOpen(true)}
          size="large"
        />
      </AddButtonWrapper>
    </Layout>
  );
};

export default Projects;
