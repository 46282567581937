import { Alert, Box, styled } from "@mui/material";
import { useAppSelector } from "../../store";
import { useDispatch } from "react-redux";
import { closeAlert } from "../../features/appSlice";
import { useEffect } from "react";

const AlertWrapper = styled(Box)(() => ({
  position: "fixed",
  zIndex: 99999,
  width: "100%",
  left: 0,
  display: "flex",
  justifyContent: "center",
  transition: "all 0.5s ease",
}));

const AppAlert = () => {
  const dispatch = useDispatch();

  const { alertTitle, opened, alertStatus } = useAppSelector((state) => state.app);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(closeAlert());
    }, 4000);

    return () => clearTimeout(timeoutId);
  }),
    [alertTitle];

  return (
    <AlertWrapper style={{ top: opened ? 10 : -50, opacity: opened ? 1 : 0 }}>
      <Alert severity={alertStatus} onClose={() => dispatch(closeAlert())}>
        {alertTitle}
      </Alert>
    </AlertWrapper>
  );
};

export default AppAlert;
