import {Typography} from "@mui/material";


interface INavigationTitleProps {
    title: string
}
const NavigationTitle = ({title}: INavigationTitleProps) => {
    return (
        <Typography
            sx={{
                fontWeight: 700,
                textAlign: 'center',
                margin: '0 auto',
                fontSize: 20
            }}
            component="p">
            {title}
        </Typography>
    )
}

export default NavigationTitle;