import {
  Avatar,
  Box,
  CssBaseline,
  Grid,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import { useDispatch } from "react-redux";
import LoginForm, { LoginFormValues } from "../components/Login/LoginForm";
import { setCredentials } from "../features/authSlice";
import { useLoginMutation } from "../services/auth";
import useTitle from "../hooks/useTitle";
import ErrorLabel from "../components/UI/ErrorLabel";
import { useMemo, useState } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const Wrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
}));

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      Space Base {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const Login = () => {
  useTitle("Login Page");

  const dispatch = useDispatch();
  const [login, { isLoading }] = useLoginMutation();

  const [error, setError] = useState<null|string>(null);

  const submitHandler = async (values: LoginFormValues) => {
      const data = await login({
          password: values.password,
          username: values.name,
      })
          .unwrap()
          .then((data) => {
              dispatch(setCredentials(data));
          })
          .catch((err) => {
              if(err.data.non_field_errors) {
                  setError(err.data.non_field_errors);
              }

          })
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(https://source.unsplash.com/random?wallpapers)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box sx={{ mt: 1 }}>
            {error && <ErrorLabel text={error} />}
            <LoginForm loading={isLoading} onSubmit={submitHandler} />
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
