import {Project} from "../services/projects";
import {useMemo} from "react";
import {IFilterItems} from "../components/Filters";
import {ITask} from "../services/tasks";
import {useGetUserQuery} from "../services/auth";

const useFilteredTasks = (filters: IFilterItems, tasks?: ITask[]): ITask[] => {

    const {data: user} = useGetUserQuery();

    return useMemo(() => {
        if(!tasks || !user) return [];
        return tasks.filter((task) => {

            let norm = true;

            if(filters.iExecutor.selected) {
                const iExecutor = task.executors.find(executor => executor.executor.id === user.id);
                if(!iExecutor) {
                    norm = false;
                }
            }

            if(filters.iCreator.selected && task.creator.id !== user.id) {
                norm = false;
            }

           if(filters.completed?.selected && task.is_published) {
               norm = false
           }

            if(filters.accounted?.selected && !task.is_accounted) {
                norm = false
            }

           return norm;
        });
    }, [tasks, filters]);
}

export default useFilteredTasks;