import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

interface LoginData {
  username: string;
  password: string;
}

export interface IUserData {
  id: number,
  avatar?: string,
  email: string,
  first_name?: string,
  last_name?: string,
  phone?: string,
}

export interface IUpdateUserData {
  first_name?: string,
  last_name?: string,
  phone?: string,
}

interface IAuthError {
  data: {
    error: string;
  };
}

interface IChangePasswordData {
  oldPassword: string
  newPassword: string
}

export const authApi = createApi({
  reducerPath: "authApi",
  tagTypes: ["User"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("Authorization", `Token ${token}`);
      }
      return headers;
    },
  }) as BaseQueryFn<string | FetchArgs, unknown, IAuthError, {}>,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials: LoginData) => ({
        url: "user/token/login",
        method: "POST",
        body: {
          password: credentials.password,
          email: credentials.username
        },
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: "user/token/logout",
        method: "POST",
      }),
    }),
    getUser: builder.query<IUserData, void>({
      query: () => "user/me/get",
      providesTags: ["User"],
    }),
    getUsers: builder.query<IUserData[], void>({
      query: () => "user/team/list",
    }),
    updateUser: builder.mutation({
      query: (data: IUpdateUserData) => ({
        url: "user/me/path",
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["User"],
    }),
    changePassword: builder.mutation({
      query: (data: IChangePasswordData) => ({
        url: "user/me/password/path",
        method: "PATCH",
        body: {
          old_password: data.oldPassword,
          new_password: data.newPassword
        },
      }),
      invalidatesTags: ["User"],
    }),
    updateUserAvatar: builder.mutation({
      query: (image: File) => {
        let formData = new FormData();
        formData.append("avatar", image);
        return {
          url: "user/me/avatar/path",
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["User"],
    }),
    removeUser: builder.mutation({
      query: (userId: string) => ({
        url: "user/",
        method: "DELETE",
        body: {
          user_id: userId,
        },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useGetUserQuery,
  useGetUsersQuery,
  useUpdateUserMutation,
  useUpdateUserAvatarMutation,
  useRemoveUserMutation,
  useChangePasswordMutation
} = authApi;
