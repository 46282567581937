// import Layout from "../components/Layout";
// import {
//   useAddChapterMutation,
//   useGetChapterQuery,
//   useGetChaptersQuery,
//   useRemoveChapterMutation,
// } from "../services/chapters";
// import { useNavigate, useParams } from "react-router-dom";
// import Chapters, { Inputs } from "../components/Chapters";
// import ProjectNavigation from "../components/ProjectNavigation";
// import { SubmitHandler } from "react-hook-form";
// import { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import { appAlert } from "../features/appSlice";
// import BoardsList from "../components/BoardsList";
// import { Box, Button } from "@mui/material";
// import {
//   useUpdateDocksBoardMutation,
//   useUpdateMediaBoardMutation,
//   useUpdateCommentBoardMutation,
//   useUpdateEditorBoardMutation,
// } from "../services/chapters";
// import FileList from "../components/Blocks/FileList";
// import { useDeleteChapterBoardElementMutation } from "../services/chapters";
// import { CommentContent } from "../components/TaskBody";
// import { OutputData } from "@editorjs/editorjs";
// import OverLay from "../components/UI/OverLay";
// import useTitle from "../hooks/useTitle";
//
// const ProjectChapters = () => {
//   useTitle("Project Information");
//
//   const { slug, id } = useParams();
//   // const {
//   //   data: project,
//   //   isSuccess,
//   //   isLoading: isProjectLoading,
//   // } = useGetProjectBySlugQuery(slug as string);
//   const project = undefined;
//   const isProjectLoading = true;
//   const isSuccess = false;
//   const { data: chapters, isLoading: isChaptersLoading } = useGetChaptersQuery(
//     project?.project.id as string,
//     { skip: !isSuccess }
//   );
//   const { data: chapter, isLoading: isChapterLoading } = useGetChapterQuery(
//     id as string,
//     { skip: !id }
//   );
//   const [addChapter] = useAddChapterMutation();
//
//   const addChapterHandler: SubmitHandler<Inputs> = (values) => {
//     if (project) {
//       addChapter({
//         name: values.name,
//         parent_project: project.project.id,
//       });
//     }
//   };
//
//   const dispatch = useDispatch();
//   const [removeChapter] = useRemoveChapterMutation();
//
//   const removeHandler = (chapterId: string) => {
//     removeChapter(chapterId)
//       .unwrap()
//       .then(() => {
//         dispatch(appAlert({ title: "Элемент удален" }));
//       });
//   };
//
//   const navigate = useNavigate();
//
//   const changeChapterHandler = (id: string) => {
//     navigate(`/projects/${slug}/chapters/${id}`);
//   };
//
//   const [updateMediaBoard] = useUpdateMediaBoardMutation();
//   const [updateDocksBoard] = useUpdateDocksBoardMutation();
//
//   const updateMediaHandler = (board_id: string, files: FileList) => {
//     updateMediaBoard({
//       board_id,
//       images: files,
//     });
//   };
//
//   const updateFilesHandler = (board_id: string, files: FileList) => {
//     updateDocksBoard({
//       board_id,
//       files,
//     });
//   };
//
//   const [removeElement] = useDeleteChapterBoardElementMutation();
//
//   const removeBoardHandler = (boardId: string, url: string) => {
//     removeElement({
//       board_id: boardId,
//       url_for_delete: url,
//     });
//   };
//
//   const [commentContent, setCommentContent] = useState<CommentContent | null>(
//     null
//   );
//   const [editorContent, setEditorContent] = useState<CommentContent | null>(
//     null
//   );
//
//   const [updateComment, { isLoading: isCommentUpdating }] =
//     useUpdateCommentBoardMutation();
//   const [updateEditor, { isLoading: isEditorUpdating }] =
//     useUpdateEditorBoardMutation();
//
//   const saveHandler = () => {
//     if (commentContent) {
//       updateComment({
//         board_id: commentContent.boardId,
//         content: commentContent.content,
//       })
//         .unwrap()
//         .then(() => {
//           setCommentContent(null);
//         });
//     }
//     if (editorContent) {
//       updateEditor({
//         board_id: editorContent.boardId,
//         content: editorContent.content,
//       })
//         .unwrap()
//         .then(() => {
//           setEditorContent(null);
//         });
//     }
//   };
//
//   return (
//     <Layout
//       navigation={<ProjectNavigation />}
//       menu={
//         <Chapters
//           onRemove={removeHandler}
//           onAdd={addChapterHandler}
//           isLoading={isChaptersLoading || isProjectLoading}
//           chapters={chapters}
//           onClick={changeChapterHandler}
//         />
//       }
//     >
//       <Box
//         sx={{
//           position: "relative",
//         }}
//       >
//         {(isEditorUpdating || isCommentUpdating) && <OverLay />}
//         {chapter?.boards && (
//           <Button
//             disabled={!editorContent && !commentContent}
//             onClick={saveHandler}
//             variant="contained"
//           >
//             Save
//           </Button>
//         )}
//
//         <Box
//           sx={{
//             display: "grid",
//             rowGap: 3,
//             gridTemplateColumns: "repeat(3, 1fr)",
//           }}
//         >
//           {chapter && (
//             <BoardsList
//               boards={chapter.boards}
//               onUpdateEditor={(id: string, data: OutputData) =>
//                 setEditorContent({ content: data, boardId: id })
//               }
//               onUpdateComment={(id: string, data: OutputData) =>
//                 setCommentContent({ content: data, boardId: id })
//               }
//               onUpdateMedia={updateMediaHandler}
//               onRemoveMedia={removeBoardHandler}
//               onUpdateFiles={updateFilesHandler}
//             />
//           )}
//         </Box>
//       </Box>
//     </Layout>
//   );
// };
//

const ProjectChapters = () => {
  return null;
}
export default ProjectChapters;
