import Login from "./pages/Login"
import Projects from "./pages/Projects"
import {
    LOGIN_ROUTE,
    PROJECTS_ROUTE,
    PROJECT_TASKS_ROUTE,
    CREATE_TASK_ROUTE,
    USERS_ROUTE,
    TASK_ROUTE, PROJECT_CHAPTERS_ROUTE, PROJECT_CHAPTER_ROUTE, TASKS_ROUTE
} from "./utils/consts"
import ProjectTasks from "./pages/ProjectTasks";
import CreateTask from "./pages/CreateTask";
import Users from "./pages/Users";
import Task from "./pages/Task";
import ProjectChapters from "./pages/ProjectChapters";
import Tasks from "./pages/Tasks";

export const publicRoutes = [
    {
        path: LOGIN_ROUTE,
        component: <Login/>
    }
]

export const privateRoutes = [
    {
        path: PROJECTS_ROUTE,
        component: <Projects/>
    },
    {
        path: TASKS_ROUTE,
        component: <Tasks/>
    },
    {
        path: PROJECT_TASKS_ROUTE,
        component: <ProjectTasks/>
    },
    {
        path: PROJECT_CHAPTERS_ROUTE,
        component: <ProjectChapters/>
    },
    {
        path: PROJECT_CHAPTER_ROUTE,
        component: <ProjectChapters/>
    },
    {
        path: TASK_ROUTE,
        component: <Task/>
    },
    {
        path: CREATE_TASK_ROUTE,
        component: <CreateTask/>
    },
    {
        path: USERS_ROUTE,
        component: <Users/>,
        root: true
    }
]
