import {Box, Button, styled, TextField, Typography, FormHelperText} from "@mui/material";
import TaskIcon from "../UI/TaskIcon";
import DnDArea from "../UI/DnDArea";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import ruLocale from "date-fns/locale/ru";
import {SubmitHandler, useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

const TaskIconWrapper = styled(Box)(({theme}) => ({
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.primary.main,
    marginRight: '25px',
    cursor: 'pointer',
    '&:hover': {
        background: theme.palette.primary.light,
    }
}));

const Heading = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px'
}));

const Title = styled(Typography)(() => ({
    fontWeight: 500,
    fontSize: '20px'
}));

const Footer = styled(Box)(() => ({
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between'
}));

export type SaveShortTaskValues = {
    dateTo: Date,
    title: string,
    linkToResource: string,
}

interface ICreateShortTaskModalProps {
    onCancel: () => void,
    onSave: (data: SaveShortTaskValues) => void,
    error: null | string
}

type Inputs = {
    title: string,
    linkToResource: string,
}

const CreateShortTaskModal = ({onCancel, onSave, error}: ICreateShortTaskModalProps) => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm<Inputs>();
    const [date, setDate] = useState<Date | null>(new Date());

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        if(data.title && data.linkToResource && date) {
            onSave({
                title: data.title,
                linkToResource: data.linkToResource,
                dateTo: date,
            });
        }
    }

    // useEffect(() => {
    //     console.log(date?.toISOString())
    // }, [date]);


    const navigate = useNavigate();
    const {slug} = useParams();


    return (
        <div>
            <Heading>
                <Title>
                    Новый таск
                </Title>
            </Heading>
            <Box
                sx={{
                    display: 'grid',
                    rowGap: '30px'
                }}
            >
                <TextField
                    error={!!errors.title}
                    helperText={errors.title ? errors.title.message : undefined}
                    {...register("title", { required: {value: true, message: 'Это обязательное поле'}, minLength: {value: 4, message: 'Минимум 4 символа'} })}
                    multiline={true}
                    minRows={3}
                    label="Краткое описание"/>
                <TextField
                    error={!!errors.linkToResource} helperText={errors.linkToResource ? "Это обязательное поле" : undefined} {...register("linkToResource", { required: true })}
                    label="Ссылка на ресурс"/>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
                    <DatePicker
                        label="Выполнить до"
                        value={date}
                        onChange={(newValue) => {
                            setDate(newValue);
                        }}
                    />
                </LocalizationProvider>
                {
                    error &&
                    <FormHelperText error={!!error}>
                        {error}
                    </FormHelperText>
                }
            </Box>


            <Footer>
                <Button variant="text" onClick={onCancel}>Отменить</Button>
                <Button variant="contained" onClick={handleSubmit(onSubmit)}>Сохранить</Button>
            </Footer>
        </div>
    );
}

export default CreateShortTaskModal;
