import {MouseEvent, useState} from 'react';
import {Box, Menu, MenuItem, Skeleton, styled} from "@mui/material";
import AddButton from "./UI/AddButton";
import UserListItem from "./UserListItem";
import {
    IProjectUser,
    useAddProjectUserMutation,
    useGetProjectPotentialParticipantsQuery, useGetProjectQuery,
    useGetProjectUsersQuery, useRemoveProjectUserMutation, useUpdateProjectUserMutation
} from "../services/projects";
import Modal from "./UI/Modal";
import UsersSelect from "./UsersSelect";
import {useDispatch} from "react-redux";
import {appAlert} from "../features/appSlice";
import Select from "@mui/material/Select";
import {IUserData} from "../services/auth";
import {IUser} from "../../types";

const StyledAddButtonContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20
});

interface IUsersListProps {
    projectId?: string
    onCreateTask?: (userId: string) => void
}

const ProjectUsersList = ({projectId, onCreateTask}: IUsersListProps) => {
    const { data: users, isLoading: isUsersLoading, isFetching: isUsersFetching } = useGetProjectUsersQuery(
        projectId as string,
        { skip: !projectId }
    );

    const { data: potentialUsers, isLoading: isPotentialUsersLoading } = useGetProjectPotentialParticipantsQuery(
        projectId as string,
        { skip: !projectId }
    );

    const { data: project, isLoading: isProjectLoading } = useGetProjectQuery(
        projectId as string,
        { skip: !projectId }
    );

    const dispatch = useDispatch();

    const [addProjectUser] = useAddProjectUserMutation();
    const [removeProjectUser] = useRemoveProjectUserMutation();

    const [isAddProjectUserModalOpen, setIsAddProjectUserModalOpen] = useState(false);
    const [selectedNewUser, setSelectedNewUser] = useState('');
    const [isUserAddedModalOpen, setIsUserAddedModalOpen] = useState(false);

    const handleAddProjectUser = () => {
        if(projectId && selectedNewUser) {
            addProjectUser({userId: selectedNewUser, projectId});
            setIsAddProjectUserModalOpen(false);
        }
    }

    const [selectedUser, setSelectedUser] = useState<null | IProjectUser>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleRemoveUser = () => {
        if(selectedUser) {
            removeProjectUser(selectedUser.id) .unwrap()
                .catch((err) => {
                    dispatch(appAlert({title: err.data.detail, status: 'error'}))
                });
            setIsAddProjectUserModalOpen(false);
            handleClose();
        }
    }

    const handleOpen = (e: MouseEvent<HTMLButtonElement>, user: IProjectUser) => {
        setAnchorEl(e.currentTarget);
        setSelectedUser(user);
    }

    const createTaskHandler = () => {
        if(onCreateTask && selectedUser) {
            onCreateTask(selectedUser.user.id.toString());
        }
    }

    const [isChangeRoleModalOpen, setIsChangeRoleModalOpen] = useState(false);
    const [selectedRole, setSelectedRole] = useState<string | null>('BRO');

    const [updateProjectUser, {isLoading: isUserUpdating}] = useUpdateProjectUserMutation();
    const changeRoleHandler = () => {
        if(selectedUser && selectedRole) {
            updateProjectUser({id: selectedUser.id, role: selectedRole})
                .unwrap()
                .catch((err) => {
                    dispatch(appAlert({title: err.data.detail, status: 'error'}))
                })
                .finally(() => {
                    setAnchorEl(null);
                });
        }
    }


    const [activeUser, setActiveUser] = useState<IUser | null>(null);
    const [modalAnchorEl, setModalAnchorEl] = useState<null | HTMLElement>(null);
    const userModalOpen = Boolean(modalAnchorEl);

    const openModal = (event: MouseEvent<HTMLButtonElement>, user: IUser) => {
        setModalAnchorEl(event.currentTarget);
        setActiveUser(user);
    }

    return (
        <>
            <Menu
                anchorEl={modalAnchorEl}
                open={userModalOpen}
                onClose={() => setModalAnchorEl(null)}
            >
                {
                    activeUser &&
                    <>
                        <img
                            style={{
                                width: '500px',
                                objectFit: 'cover'
                            }}
                            src={activeUser.avatar ? activeUser.avatar : 'cat.jpg'}
                            alt={activeUser.first_name}/>
                        <Box sx={{px: 2, pt: 2}}>
                            {activeUser.first_name} {activeUser.last_name}
                        </Box>
                        <Box sx={{px: 2, pt: 2, pb: 2}}>
                            {activeUser.email}
                        </Box>
                    </>
                }
            </Menu>
            <Modal
                title='Участник добавлен'
                cancelButtonText="ОК"
                open={isUserAddedModalOpen}
                onClose={() => setIsUserAddedModalOpen(false)}
            />
            <Modal
                open={isChangeRoleModalOpen}
                onClose={() => setIsChangeRoleModalOpen(false)}
                title="Change user role"
                cancelButtonText="Close"
                submitButtonText="Submit"
                onSubmit={changeRoleHandler}
                loading={isUserUpdating}
            >
                <Select
                    sx={{width: '100%'}}
                    value={selectedRole}
                    onChange={(event) => setSelectedRole(event.target.value as string)}
                >
                    <MenuItem value="BRO">BRO</MenuItem>
                    <MenuItem value="BRONAGER">BRONAGER</MenuItem>
                </Select>
            </Modal>
            <Menu
                id="usermenu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={createTaskHandler}>
                    Create task
                </MenuItem>
                <MenuItem onClick={() => setIsChangeRoleModalOpen(true)}>
                    Change role
                </MenuItem>
                <MenuItem onClick={handleRemoveUser}>
                    Remove from project
                </MenuItem>
            </Menu>
            <Modal
                title='Добавить участника проекта'
                submitButtonText="ДОБАВИТЬ"
                cancelButtonText="ОТМЕНИТЬ"
                open={isAddProjectUserModalOpen}
                onClose={() => setIsAddProjectUserModalOpen(false)}
                onSubmit={handleAddProjectUser}
            >
                <UsersSelect
                    label="Участник проекта"
                    users={potentialUsers}
                    onChange={(e) => {
                        const {
                            target: { value },
                        } = e;
                        setSelectedNewUser(value);
                    }}
                    value={selectedNewUser}
                />
            </Modal>
            {
                project &&
                <UserListItem
                    onAvatarClick={(e) => openModal(e, project.owner)}
                    isOwner={true}
                    avatar={project.owner.avatar}
                    name={project.owner.first_name ? project.owner.first_name : project.owner.email}
                    role="OWNER"
                />
            }
            {
                isUsersLoading || isUsersFetching ?
                [0, 1, 2, 3].map(item => (
                    <Box
                        key={item}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '8px 16px'
                        }}
                    >
                        <Skeleton
                            sx={{
                                mr: '25px'
                            }}
                            width={40}
                            height={40}
                            variant="circular" />
                        <Box>
                            <Skeleton
                                sx={{
                                    mb: 1
                                }}
                                width={100}
                                height={20}
                                variant="rectangular"
                            />
                            <Skeleton
                                width={70}
                                height={20}
                                variant="rectangular"
                            />
                        </Box>
                        <Skeleton
                            sx={{
                                marginLeft: 'auto'
                            }}
                            width={30}
                            height={30}
                            variant="circular" />
                    </Box>
                )) :
                    users?.map(user => (
                        <UserListItem
                            key={user.id}
                            isOwner={false}
                            name={user.user.first_name ? user.user.first_name : user.user.email}
                            role={user.role}
                            avatar={user.user.avatar}
                            onClick={(e) => handleOpen(e, user)}
                            onAvatarClick={(e) => openModal(e, user.user)}
                        />
                    ))
            }
            <StyledAddButtonContainer>
                <AddButton
                    size="large"
                    background="#000"
                    color="#fff"
                    onClick={() => setIsAddProjectUserModalOpen(true)}
                />
            </StyledAddButtonContainer>
        </>
    );
}

export default ProjectUsersList;

