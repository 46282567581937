import { useNavigate, useParams } from "react-router-dom";
import { useCreateTaskMutation } from "../services/tasks";
import TaskBody, { SaveTaskValues } from "../components/TaskBody";
import Layout from "../components/Layout";
import useQuery from "../hooks/useQuery";
import useTitle from "../hooks/useTitle";

const CreateTask = () => {
  useTitle("Create Task");
  const { slug } = useParams();
  const navigate = useNavigate();

  const [createTask] = useCreateTaskMutation();

  // const { data: project } = useGetProjectBySlugQuery(slug as string);

  // const saveHandler = (data: SaveTaskValues) => {
  //   if (project?.project.id) {
  //     createTask({
  //       project: project?.project.id,
  //       title: data.shortDesk,
  //       linkToResource: data.linkToResource,
  //       dateTo: data.date.toISOString(),
  //     })
  //       .unwrap()
  //       .then(() => console.log("success"));
  //   }
  // };

  const query = useQuery();

  return (
    <Layout>
      {/*<TaskBody*/}
      {/*  title={query.get("shortDesk") || undefined}*/}
      {/*  linkToResource={query.get("linkToResource") || undefined}*/}
      {/*  onCancel={() => navigate(`/projects/${slug}/tasks`)}*/}
      {/*  onSave={() => {}}*/}
      {/*/>*/}
    </Layout>
  );
};

export default CreateTask;
