import {
    Avatar,
    Box,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    Menu,
    MenuItem, styled,
    Typography
} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import {IUserData, useGetUsersQuery} from "../../services/auth";
import {useState, MouseEvent, Fragment} from "react";

const UsersListItem = styled(ListItem)(({theme}) => ({
    [theme.breakpoints.down('sm')]: {
        paddingLeft: '7px'
    }
}));

const UsersList = () => {

    const {data: users} = useGetUsersQuery();

    const [activeUser, setActiveUser] = useState<IUserData | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const openModal = (event: MouseEvent<HTMLButtonElement>, user: IUserData) => {
        setAnchorEl(event.currentTarget);
        setActiveUser(user);
    }

    return (
        <>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
            >
                {
                    activeUser &&
                   <>
                       <img
                           style={{
                               width: '500px',
                               objectFit: 'cover'
                           }}
                           src={activeUser.avatar ? activeUser.avatar : 'cat.jpg'}
                           alt={activeUser.first_name}/>
                       <Box sx={{px: 2, pt: 2}}>
                           {activeUser.first_name} {activeUser.last_name}
                       </Box>
                       <Box sx={{px: 2, pt: 2, pb: 2}}>
                           {activeUser.email}
                       </Box>
                   </>
                }
            </Menu>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {
                    users?.map(user => (
                        <Fragment key={user.id}>
                            <UsersListItem>
                                <ListItemAvatar sx={{ml: -1, mr: 1}}>
                                    <IconButton onClick={(e) => openModal(e, user)}>
                                        <Avatar alt={user.first_name} src={user.avatar}/>
                                    </IconButton>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={user.first_name}
                                    secondary={user.email}
                                />
                            </UsersListItem>
                            <Divider/>
                        </Fragment>
                    ))
                }
            </List>
        </>
    )
}

export default UsersList;