import {ITask} from "../services/tasks";
import {IProjectData} from "../services/projects";

export interface TasksByProjects {
    [projectId: string]: {
        tasks: ITask[],
        project: IProjectData
    }
}

const useTasksByProjects = (tasks?: ITask[]): TasksByProjects => {
    if(!tasks) return {};

    const tasksByProjects: TasksByProjects = {};

    tasks.forEach(task => {
        if(tasksByProjects[task.project.id]) {
            tasksByProjects[task.project.id].tasks.push(task);
        } else {
            tasksByProjects[task.project.id] = {
                tasks: [task],
                project: task.project
            };
        }
    });

    console.log(tasksByProjects)

    return tasksByProjects;
}

export default useTasksByProjects;