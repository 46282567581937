import {Box, BoxProps, styled} from "@mui/material";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {ChangeEvent, useEffect, useRef, useState} from "react";
import useDnD from "../../hooks/useDnD";
import DnD from "./DnD";

interface DnDAreaWrapperProps extends BoxProps {
    dragging?: boolean,
    background?: string,
}

const DnDAreaWrapper = styled(Box)<DnDAreaWrapperProps>(({theme, dragging, background}) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 205,
    alignItems: 'center',
    background: background ? `url(${background}) no-repeat center /contain` : theme.palette.grey['100'],
    border: dragging ? `2px solid ${theme.palette.primary.main}` : `2px dashed ${theme.palette.grey['400']}`,
    padding: '40px',
    position: 'relative'
}));

const Icon = styled(InsertDriveFileIcon)(({theme}) => ({
    width: '40px',
    height: '40px',
    color: theme.palette.grey['500']
}));

const StyledLabel = styled('p')(() => ({
    fontSize: '12px',
    fontWeight: 300,
    textAlign: 'center'
}));

interface IDnDAreaProps {
    label: string,
    onAdd?: (file: File, files: FileList) => void,
    background?: string
}

interface IDragEvent extends DragEvent {
    dataTransfer: DataTransfer;
}

const DnDArea = ({label, onAdd, background}: IDnDAreaProps) => {
    const dropRef = useRef<HTMLDivElement>();

    const {dragging, file, files} = useDnD(dropRef);

    const [fileName, setFileName] = useState(label);

    useEffect(() => {
        if(onAdd && file && files) {
            onAdd(file, files);
        }
        if(file) {
            setFileName(file.name);
        }
    }, [file]);

    const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        if(onAdd && e.target.files) {
            onAdd(e.target.files[0], e.target.files);
            setFileName(e.target.files[0].name);
        }
    }

    return (
        <Box ref={dropRef}>
            <DnD
                label={fileName}
                showInput={true}
                background={background}
                dragging={dragging}
                onChange={changeHandler}
            />
        </Box>
    );
}

export default DnDArea;
