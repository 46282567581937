import {styled, Table, TableCell, TableHead, TableRow, TableBody} from "@mui/material";
import Layout from "../components/Layout";
import UsersItem from "../components/Users/UsersItem";
import {useGetUsersQuery, useGetUserQuery, useRemoveUserMutation} from "../services/auth";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {appAlert} from "../features/appSlice";
import useTitle from "../hooks/useTitle";

const TableHeading = styled(TableRow)(({theme}) => ({
    background: theme.palette.grey["500"]
}));

const HeadingTableCell = styled(TableCell)(() => ({
    padding: '10px',
    color: '#fff',
    textAlign: 'center'
}));

const Users = () => {
    // useTitle('Users');
    //
    // const {data: users} = useGetUsersQuery();
    // const {data: user} = useGetUserQuery();
    //
    // const navigate = useNavigate();
    //
    // useEffect(() => {
    //     if(!user?.roles.includes('ROOT')) {
    //         navigate('/');
    //     }
    // }, []);
    //
    //
    // const dispatch = useDispatch();
    // const [removeUser] = useRemoveUserMutation();
    //
    // const removeUserHandler = (userId: string) => {
    //     removeUser(userId)
    //         .unwrap()
    //         .then(() => {
    //             dispatch(appAlert({title: 'Пользователь успешно удален'}));
    //         });
    // }
    //
    // return (
    //     <Layout>
    //         <Table>
    //             <TableHead>
    //                 <TableHeading>
    //                     <HeadingTableCell>
    //                         Имя
    //                     </HeadingTableCell>
    //                     <HeadingTableCell>
    //                         Контакты
    //                     </HeadingTableCell>
    //                     <HeadingTableCell>
    //                         Дата создания
    //                     </HeadingTableCell>
    //                     <HeadingTableCell>
    //
    //                     </HeadingTableCell>
    //                 </TableHeading>
    //             </TableHead>
    //             <TableBody>
    //                 {
    //                     users?.map(user => (
    //                         <UsersItem
    //                             key={user.id}
    //                             avatar={user.avatar_url}
    //                             created={user.createdAt}
    //                             phone={user.phone}
    //                             email={user.email}
    //                             name={user.name}
    //                             onRemove={() => removeUserHandler(user.id)} />
    //                     ))
    //                 }
    //             </TableBody>
    //         </Table>
    //     </Layout>
    // );
    return null;
}

export default Users;
