import {IconButton, Link, ListItem, ListItemIcon, ListItemText, styled} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from '@mui/icons-material/Delete';

const ListItemStyled = styled(ListItem)(() => ({
    paddingLeft: 0,
    paddingRight: 0,
    borderBottom: '1px dashed rgba(38, 50, 56, 0.16)'
}));

interface IFileListItemProps {
    name: string,
    url: string,
    onDelete: () => void
}

const FileListItem = ({name, url, onDelete}: IFileListItemProps) => {
    return (
        <ListItemStyled>
            <ListItemIcon>
                <DescriptionIcon />
            </ListItemIcon>
            <ListItemText
                primary={name}
            />
            <IconButton onClick={onDelete}>
                <DeleteIcon />
            </IconButton>
            <IconButton component={'a'} href={process.env.REACT_APP_BACKEND_URL + url} target='_blank'>
                <DownloadIcon />
            </IconButton>
        </ListItemStyled>
    );
}

export default FileListItem;
