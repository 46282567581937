import {Box, Skeleton, TableCell, TableRow} from "@mui/material";

const TasksListSkeleton = () => {
    return (
        <>
            {
                [0, 1, 2, 3].map((item) => (
                    <TableRow key={item}>
                        <TableCell>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Skeleton
                                    sx={{
                                        mr: "25px",
                                    }}
                                    width={24}
                                    height={24}
                                    variant="circular"
                                />
                                <Skeleton width={200} height={20} variant="rectangular" />
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Skeleton width={100} height={20} variant="rectangular" />
                        </TableCell>
                        <TableCell>
                            <Box
                                sx={{
                                    display: "flex",
                                }}
                            >
                                <Skeleton
                                    sx={{
                                        mr: "25px",
                                    }}
                                    width={40}
                                    height={40}
                                    variant="circular"
                                />
                                <Skeleton
                                    sx={{
                                        mr: "25px",
                                    }}
                                    width={40}
                                    height={40}
                                    variant="circular"
                                />
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Skeleton
                                sx={{
                                    mr: "25px",
                                }}
                                width={40}
                                height={40}
                                variant="circular"
                            />
                        </TableCell>
                        <TableCell>

                        </TableCell>
                    </TableRow>
                ))
            }
        </>
    )
}

export default TasksListSkeleton;