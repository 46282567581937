import TaskBody, {SaveTaskValues} from "../components/TaskBody";
import {useNavigate, useParams} from "react-router-dom";
import {
    Boards,
    useAddTaskBoardMutation, useAddTaskImageMutation, useGetTaskAdditionsQuery,
    useGetTaskQuery,
    useUpdateCommentBoardMutation,
    useUpdateEditorBoardMutation,
    useUpdateTaskMutation
} from "../services/tasks";
import Layout from "../components/Layout";
import TaskBodySkeleton from "../components/TaskBodySkeleton";
import useTitle from "../hooks/useTitle";
import {OutputData} from "@editorjs/editorjs";
import {useDispatch} from "react-redux";
import {appAlert} from "../features/appSlice";
import NavigationTitle from "../components/UI/NavigationTitle";
import {Box, styled} from "@mui/material";

const Container = styled(Box)(() => ({
    maxWidth: '1600px',
    margin: '0 auto'
}));


const Task = () => {
    const {projectId, taskId} = useParams();
    const navigate = useNavigate();
    const {data: task} = useGetTaskQuery(taskId as string, {skip: !taskId});
    useTitle(task ? 'Task '+task.title : 'Task');
    const [updateTask, {isLoading}] = useUpdateTaskMutation();
    const [addTaskImage] = useAddTaskImageMutation();
    const {data: additions} = useGetTaskAdditionsQuery(taskId as string, {skip: !taskId});
    const dispatch = useDispatch();
    const saveHandler = (data: SaveTaskValues) => {
        if(taskId) {
            updateTask({
                id: taskId,
                title: data.title,
                dateTo: data.dateTo.toISOString(),
                linkToResource: data.linkToResource,

            });
            if(data.image) {
                addTaskImage({id: taskId, image: data.image});
            }
        }
    }

    const [addBoard, {isLoading: isBoardAdding}] = useAddTaskBoardMutation();

    const addBoardHandler = (content: OutputData) => {
        if(taskId) {
            addBoard({
                taskId, content
            }).unwrap()
                .catch(err => {
                    dispatch(appAlert({title: err.data.detail, status: 'error'}))
                });
        }
    }

    return (
      <Layout
          hideDrawer={true}
      >
          <Container>
              {
                  task ?
                      <TaskBody
                          task={task}
                          taskId={taskId}
                          title={task?.title}
                          linkToResource={task.link_to_resource}
                          cancelHref={`/projects/${projectId}/tasks`}
                          timeToCompletion={task.time_to_completion}
                          image={task?.image}
                          isLoading={isLoading}
                          additions={additions}
                          onAddBoard={addBoardHandler}
                          isAdditionAdding={isBoardAdding}
                          onSave={saveHandler}
                          files={task.files}
                      />
                      : <TaskBodySkeleton/>
              }
          </Container>
      </Layout>
    );
}

export default Task;

