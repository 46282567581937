import {
  Link,
  styled,
  Typography,
  AppBar as MuiAppBar,
  Drawer as MuiDrawer,
  AppBarProps as MuiAppBarProps,
  createTheme,
  ThemeProvider,
  Box,
  CssBaseline,
  Toolbar,
  IconButton,
  List,
  Divider,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { ReactNode, useState } from "react";
import NavBar from "./NavBar";
import ProjectUsersList from "./ProjectUsersList";
import ProjectNavigation from "./ProjectNavigation";
import theme from "../theme";
import {useAppSelector} from "../store";
import {useDispatch} from "react-redux";
import {toggleSidebar} from "../features/appSlice";

const drawerWidth = 280;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
  [theme.breakpoints.down('md')]: {
    position: 'absolute'
  }
}));

const ContentWrapper = styled(Box)(({theme}) => ({
  padding: '30px 32px 45px 32px',
  [theme.breakpoints.down('lg')]: {
    padding: '20px 20px 30px 20px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '20px 10px 30px 10px'
  }
}));

const HeadingAndContentWrapper = styled(Box)(({theme}) => ({
  [theme.breakpoints.down('md')]: {
    marginLeft: theme.spacing(9)
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(7)
  }
}))

interface ILayoutProps {
  children?: ReactNode;
  menu?: ReactNode;
  navigation?: ReactNode;
  heading?: ReactNode;
  title?: string;
  hideDrawer?: boolean
}

const Layout = ({ children, menu, navigation, heading, title, hideDrawer }: ILayoutProps) => {
  const {isSidebarOpened} = useAppSelector((state) => state.app);
  const dispatch = useDispatch();

  const toggleDrawer = () => {
    dispatch(toggleSidebar());
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <NavBar hideDrawer={hideDrawer} title={title} isOpenSidebar={isSidebarOpened} toggleDrawer={toggleDrawer} />
        {
          !hideDrawer &&
            <Drawer
                sx={{
                  height: "100vh",
                  overflow: "auto",
                }}
                variant="permanent"
                open={isSidebarOpened}
            >
              <Toolbar
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    px: [1],
                  }}
              >
                {navigation || <Box></Box>}
                <IconButton onClick={toggleDrawer}>
                  <ChevronLeftIcon />
                </IconButton>
              </Toolbar>
              {menu}
            </Drawer>
        }
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <HeadingAndContentWrapper>
            {heading}
            <ContentWrapper>
              {children}
            </ContentWrapper>
          </HeadingAndContentWrapper>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Layout;
