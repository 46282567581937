import {useState, MouseEvent} from 'react';
import {
    Box,
    IconButton,
    Menu,
    styled,
    Avatar,
    MenuItem
} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {IUpdateUserData, useGetUserQuery, useUpdateUserAvatarMutation, useUpdateUserMutation} from '../services/auth';
import {FieldValues, SubmitHandler, useForm} from "react-hook-form";
import UserSettingsFields, {Inputs} from "./UserSettingsFields";
import {useDispatch} from "react-redux";
import {appAlert} from "../features/appSlice";
import Modal from "./UI/Modal";
import DnDArea from "./UI/DnDArea";
import {logout} from "../features/authSlice";
import ChangePasswordModal from "./ChangePasswordModal";

interface IUserSettingsProps {
    onClose: () => void,
    open: boolean
}

const UserImage = styled('img')(`
    width: 100%;
`)

const SettingsWrapper = styled(Box)(({theme}) => ({
    maxWidth: '400px',
    maxHeight: 'calc(100vh - 70px)',
    width: '100%',
    position: 'fixed',
    right: '20px',
    top: '65px',
    borderRadius: '4px',
    overflow: 'auto',
    background: '#fff',
    zIndex: 6,
    boxShadow: '1px 1px 14px 3px rgba(0, 0, 0, .12)',
    '::-webkit-scrollbar': {
        width: '0'
    },
    [theme.breakpoints.down('sm')]: {
        right: 0
    }
}))

const MoreButton = styled(IconButton)(() => ({
    color: '#fff',
    position: 'absolute',
    right: '5px',
    top: '5px'
}))

const UserHeading = styled(Box)`
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    p {
        color: #263238;
        font-size: 16px;
        margin: 0;
        line-height: 0.7;
    }
    small {
        color: rgba(38, 50, 56, 0.54);
        font-size: 12px;
    }
    .MuiAvatar-root {
        margin-right: 15px;
        flex-shrink: 0;
    }
`

const SettingsBottom = styled(Box)(({theme}) => ({
    padding: '25px 60px 35px 60px',
    [theme.breakpoints.down('sm')]: {
        padding: '20px 20px 35px 20px',
    }
}))

const FieldItem = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '25px',
    'svg': {
        marginRight: '30px',
        marginTop: '15px',
    },
    '.MuiTextField-root': {
        flexGrow: 1
    }
}))

const ButtonsWrapper = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between'
}))

const BackDrop = styled(Box)(() => ({
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 5
}))


const UserSettings = ({open, onClose}: IUserSettingsProps) => {

    const [imageMenuAnchor, setImageMenuAnchor] = useState<null | HTMLElement>(null);
    const imageMenuOpen = Boolean(imageMenuAnchor);

    const {data} = useGetUserQuery();

    const [updateUserData, {isLoading}] = useUpdateUserMutation();

    const openImageMenu = (e: MouseEvent<HTMLButtonElement>) => {
        setImageMenuAnchor(e.currentTarget)
    }

    const closeImageMenu = () => {
        setImageMenuAnchor(null)
    }

    const dispatch = useDispatch();

    const updateUser: SubmitHandler<Inputs> = (data) => {
        const userData: IUpdateUserData = {

        }
        if(data.phone) {
            userData.phone = data.phone.toString();
        }
        if(data.firstName) {
            userData.first_name = data.firstName;
        }
        if(data.lastName) {
            userData.last_name = data.lastName;
        }
        updateUserData(userData)
            .unwrap()
            .then(() => {
                dispatch(appAlert({title: 'Сохранено'}));
            })
            .catch(() => {
                dispatch(appAlert({title: 'Какая-то ошибка, хз', status: 'error'}));
            });
    }

    const [isAddUserAvatarModalOpen, setIsAddUserAvatarModalOpen] = useState(false);
    const [isPasswordChangeModalOpen, setIsPasswordChangeModalOpen] = useState(false);
    const [userAvatar, setUserAvatar] = useState<File | null>(null);
    const [updateUserAvatar] = useUpdateUserAvatarMutation();

    const updateAvatarHandler = () => {
        if(userAvatar) {
            updateUserAvatar(userAvatar)
                .unwrap()
                .then(() => {
                    setIsAddUserAvatarModalOpen(false);
                    dispatch(appAlert({title: 'Фото заменено/добавлено'}));
                });
        }
    }

    if (!open) return null
    return (
        <>
            <Modal
                open={isAddUserAvatarModalOpen}
                title="Заменить/добавить фото"
                submitButtonText="СОХРАНИТЬ"
                cancelButtonText="ОТМЕНИТЬ"
                onClose={() => setIsAddUserAvatarModalOpen(false)}
                onSubmit={updateAvatarHandler}
            >
                <DnDArea
                    onAdd={(file) => setUserAvatar(file)}
                    label="Перетащите изображение сюда, чтобы добавить"/>
            </Modal>
            <ChangePasswordModal open={isPasswordChangeModalOpen} onClose={() => setIsPasswordChangeModalOpen(false)}/>
            <BackDrop
                onClick={onClose}
            />
            <Menu
                anchorEl={imageMenuAnchor}
                open={imageMenuOpen}
                onClose={closeImageMenu}
            >
                <MenuItem onClick={() => setIsAddUserAvatarModalOpen(true)}>Заменить/добавить фото</MenuItem>
                <MenuItem disabled={true}>Удалить профиль</MenuItem>
                <MenuItem onClick={() => setIsPasswordChangeModalOpen(true)}>Обновить пароль</MenuItem>
                <MenuItem onClick={() => dispatch(logout())}>Выйти</MenuItem>
            </Menu>
            <SettingsWrapper>
                <UserImage src={data?.avatar ? data?.avatar : 'cat.jpg'} alt="user avatar"/>
                <MoreButton
                    onClick={openImageMenu}
                >
                    <MoreVertIcon/>
                </MoreButton>
                <SettingsBottom>
                    <UserHeading>
                        <Avatar src={data?.avatar}/>
                        <div>
                            <p>
                                {data?.first_name} {data?.last_name}
                            </p>
                            <small>
                                {data?.email}
                            </small>
                        </div>
                    </UserHeading>
                    {
                        data && <UserSettingsFields
                            firstName={data?.first_name}
                            lastName={data?.last_name}
                            phone={data?.phone}
                            loading={isLoading}
                            onSubmit={updateUser}
                            onClose={onClose}
                        />
                    }
                </SettingsBottom>
            </SettingsWrapper>
        </>
    );
};

export default UserSettings;
