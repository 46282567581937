import {ReactNode} from 'react';
import {Box, Paper, styled, SxProps} from "@mui/material";

const Wrapper = styled(Paper)(({theme}) => ({
    position: 'relative',
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 30,
    paddingBottom: 5,
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 10,
        paddingRight: 10,
    }
}));

const Label = styled('p')(() => ({
    position: 'absolute',
    fontSize: 12,
    top: -7,
    left: 45,
    margin: 0
}));

interface IAppPaperProps {
    label?: string,
    sx?: SxProps,
    children: ReactNode
}

const AppPaper = ({label, sx, children}: IAppPaperProps) => {
    return (
        <Wrapper sx={sx}>
            <Label>
                {label}
            </Label>
            {children}
        </Wrapper>
    )
}

export default AppPaper;