import {Project} from "../services/projects";
import {useMemo} from "react";
import {useGetUserQuery} from "../services/auth";

const useProjects = (projects?: Project[], my: boolean = true) => {

    const {data: user} = useGetUserQuery();

    return useMemo(() => {
        if(!projects || !user) return [];

        return projects.filter(project => {
           return my ? project.owner.id === user.id : project.owner.id !== user.id;
        });

    }, [projects]);
}

export default useProjects;