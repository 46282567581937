import {Box, styled} from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const Wrapper = styled(Box)(() => ({
    background: 'rgba(211, 47, 47, .6)',
    border: '1px solid rgb(211, 47, 47)',
    color: '#fff',
    borderRadius: 4,
    padding: '10px 10px',
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center'
}));

const TextLabel = styled('p')(() => ({
    margin: '0 0 0 20px'
}));

interface IErrorLabelProps {
    text: string,
}

const ErrorLabel = ({text}: IErrorLabelProps) => {
    return (
        <Wrapper>
            <ErrorOutlineIcon/>
            <TextLabel>
                {text}
            </TextLabel>
        </Wrapper>
    );
}

export default ErrorLabel;

