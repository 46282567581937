import axios from "axios";
import {IPushSubscriptionData} from "../services/notifications";

function urlB64ToUint8Array(base64String: string) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    const outputData = outputArray.map((output, index) => rawData.charCodeAt(index));

    return outputData;
}

async function regSw () {
    if ('serviceWorker' in navigator) {
        let url = process.env.PUBLIC_URL + '/sw.js';
        const reg = await navigator.serviceWorker.register (url, {scope: '/'});
        console.log ('service config is', {reg});
        return reg;
    }
    return false;
}

async function subscribe (serviceWorkerReg: ServiceWorkerRegistration) {
    let subscription = await serviceWorkerReg.pushManager.getSubscription ();
    if (subscription === null) {
        subscription = await serviceWorkerReg.pushManager.subscribe ({
            userVisibleOnly: true,
            applicationServerKey: urlB64ToUint8Array(process.env.REACT_APP_PUSH_PUBLIC_KEY as string),
        });
    }
    try {
        const browserMatch = navigator.userAgent.match(/(firefox|msie|chrome|safari|trident)/ig);
        const browser = browserMatch ? browserMatch[0].toLowerCase() : '';

        const data: IPushSubscriptionData = {
            status_type: 'subscribe',
            subscription: subscription.toJSON(),
            user_agent: navigator.userAgent,
            browser
        }

        // const res = await fetch('/api/v1/webpush/save_information', {
        //     method: 'POST',
        //     body: JSON.stringify(data),
        //     headers: {
        //         'content-type': 'application/json'
        //     },
        //     credentials: "include"
        // });

        return data;
    } catch (e) {
        return false;
    }
}


export {regSw, subscribe};