import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {RootState} from "../store";
import {
    Board,
    Boards,
    IDeleteTaskBoardElementData,
    IUpdateCommentBoardData,
    IUpdateDocksBoardData,
    IUpdateMediaBoardData
} from "./tasks";

export interface IProjectChapter {
    completed: boolean;
    id: string;
    creator: string;
    parent_project: string;
    parent_chapter: string;
    children: IProjectChapter[];
    name: string;
    sort: number;
    access: any[];
    createdAt: Date;
    updatedAt: Date;
    __v: number;
}

export interface IProjectChapters {
    chapters: IProjectChapter[]
}

interface IProjectChapterBoards {
    boards: Board[];
}

interface ICompleteChapterData {
    chapter_id: string,
    completed: boolean
}

interface IAddChapterData {
    name: string,
    parent_project: string,
    parent_chapter?: string
}

interface IAddChapterBoardData {
    parent_chapter: string,
    sort: number,
    type: Boards
}

interface IRenameChapterData {
    chapter_id: string,
    name: string
}

export const chaptersApi = createApi({
    reducerPath: 'chaptersApi',
    tagTypes: ['Chapter'],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders: (headers, {getState, endpoint}) => {
            const token = (getState() as RootState).auth.token
            headers.set('Authorization', `Bearer ${token}`)
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getChapters: builder.query<IProjectChapters, string>({
            query: (projectId) => `/chapters/${projectId}`,
            providesTags: ['Chapter']
        }),
        getChapter: builder.query<IProjectChapterBoards, string>({
            query: (chapterId) => `/chapter/${chapterId}`,
            providesTags: ['Chapter']
        }),
        completeChapter: builder.mutation({
            query: (data: ICompleteChapterData) => ({
                url: 'chapter/completed',
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ['Chapter'],
        }),
        renameChapter: builder.mutation({
           query: (data: IRenameChapterData) => ({
               url: '/chapter/name',
               method: 'PUT',
               body: data
           }),
            invalidatesTags: ['Chapter'],
        }),
        addChapter: builder.mutation({
            query: (data: IAddChapterData) => ({
                url: '/chapter',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Chapter'],
        }),
        removeChapter: builder.mutation({
            query: (id: string) => ({
                url: '/chapter/' + id,
                method: 'DELETE'
            }),
            invalidatesTags: ['Chapter'],
        }),
        addChapterBoard: builder.mutation({
            query: (data: IAddChapterBoardData) => ({
                url: '/chapter/board',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Chapter'],
        }),
        updateMediaBoard: builder.mutation({
            query: (data: IUpdateMediaBoardData) => {
                let formData = new FormData();
                formData.append("board_id", data.board_id);
                for (let i = 0; i < data.images.length; i++) {
                    formData.append("images", data.images[i]);
                }
                return {
                    url: '/chapter/board-media',
                    method: 'PUT',
                    body: formData
                }
            },
            invalidatesTags: ['Chapter'],
        }),
        updateDocksBoard: builder.mutation({
            query: (data: IUpdateDocksBoardData) => {
                let formData = new FormData();
                formData.append("board_id", data.board_id);
                for (let i = 0; i < data.files.length; i++) {
                    formData.append("docks", data.files[i]);
                }
                return {
                    url: '/chapter/board-docks',
                    method: 'PUT',
                    body: formData
                }
            },
            invalidatesTags: ['Chapter'],
        }),
        updateCommentBoard: builder.mutation({
            query: (data: IUpdateCommentBoardData) => ({
                url: '/chapter/board-comment',
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ['Chapter'],
        }),
        updateEditorBoard: builder.mutation({
            query: (data: IUpdateCommentBoardData) => ({
                url: '/chapter/board-editor',
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ['Chapter'],
        }),
        deleteChapterBoardElement: builder.mutation({
            query: (data: IDeleteTaskBoardElementData) => ({
                url: '/chapter/board/element',
                method: 'DELETE',
                body: data
            }),
            invalidatesTags: ['Chapter'],
        }),
        removeChapterBoard: builder.mutation({
            query: (board_id: string) => ({
                url: 'chapter/board/'+board_id,
                method: 'DELETE'
            }),
            invalidatesTags: ['Chapter'],
        }),
    })
});

export const {
    useGetChaptersQuery,
    useGetChapterQuery,
    useCompleteChapterMutation,
    useAddChapterMutation,
    useRemoveChapterMutation,
    useRenameChapterMutation,
    useAddChapterBoardMutation,
    useUpdateMediaBoardMutation,
    useUpdateDocksBoardMutation,
    useUpdateCommentBoardMutation,
    useUpdateEditorBoardMutation,
    useDeleteChapterBoardElementMutation,
    useRemoveChapterBoardMutation
} = chaptersApi;
