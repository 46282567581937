import {MutableRefObject, RefObject, useEffect, useState} from "react";


interface IDragEvent extends DragEvent {
    dataTransfer: DataTransfer;
}

let dragCounter = 0;

function useDnd(dropRef: MutableRefObject<HTMLDivElement | undefined>, multiply: boolean = false) {
    const [dragging, setDragging] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const [files, setFiles] = useState<FileList | null>(null);

    const handleDrag = (e: IDragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    }
    const handleDragIn = (e: IDragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter++;
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDragging(true);
        }
    }
    const handleDragOut = (e: IDragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter--;
        if(dragCounter === 0) {
            setDragging(false);
        }
    }
    const handleDrop = (e: IDragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            // e.dataTransfer.clearData();
            setFile(e.dataTransfer.files[0]);
            setFiles(e.dataTransfer.files);
        }
    }

    useEffect(() => {
        const div = dropRef.current;
        if(div) {
            div.addEventListener('dragenter', (e: Event) => handleDragIn(e as IDragEvent))
            div.addEventListener('dragleave', (e: Event) => handleDragOut(e as IDragEvent))
            div.addEventListener('dragover', (e: Event) => handleDrag(e as IDragEvent))
            div.addEventListener('drop', (e: Event) => handleDrop(e as IDragEvent))
        }
    }, []);

    return {
        dragging, file, files
    };
}

export default useDnd;
