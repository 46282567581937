import AddButton from "../UI/AddButton";
import {Avatar, Box, Checkbox, IconButton, Menu, MenuItem, styled, TableCell, TableRow, Tooltip} from "@mui/material";
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import {Fragment, MouseEvent, useState} from "react";
import ShortTaskIcon from "../UI/ShortTaskIcon";
import {format, addSeconds} from 'date-fns';
import {useGetUserQuery} from "../../services/auth";
import {Executor, IExecutor, useRemoveTaskExecutorMutation} from "../../services/tasks";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TaskItemTimer from "./TaskItemTimer";
import {Link} from "react-router-dom";

const CheckboxIcon = styled('span')(() => ({
    width: '20px',
    height: '20px',
    borderRadius: '4px',
    border: '0.5px solid #979797',
    background: '#fff',
    ':hover': {
        background: '#fff',
    }
}));

const ExecutorsWrap = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    maxWidth: '500px',
    overflowX: 'auto',
    padding: '0 5px'
}));

const CheckboxIconActive = styled(CheckboxIcon)(() => ({
    border: '0.5px solid #DE05D3',
}));

const CheckboxIconChecked = styled(CheckboxIcon)({
    backgroundColor: '#137cbd',
    borderColor: 'transparent',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
});

const CheckboxIconCheckedActive = styled(CheckboxIconChecked)({
    backgroundImage: 'linear-gradient(90deg, #D500F9 0%, #FF1744 100%)',
});

const DescriptionCell = styled(Box)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    maxWidth: '450px',
    [theme.breakpoints.down('md')]: {
        maxWidth: '300px',
    }
}));

const PlayCircleFilledWhiteOutlinedIconActive = () => (
    <>
        <svg width={0} height={0}>
            <linearGradient id="paint0_linear_603_1226" x1="2.83079e-08" y1="11.871" x2="23" y2="11.871" gradientUnits="userSpaceOnUse">
                <stop stopColor="#D500F9"/>
                <stop offset="1" stopColor="#FF1744"/>
            </linearGradient>
        </svg>
        <PlayCircleFilledWhiteOutlinedIcon sx={{ fill: "url(#paint0_linear_603_1226)" }} />
    </>
)

const PauseCircleOutlineIconActive = () => (
  <>
      <svg width={0} height={0}>
          <linearGradient id="paint0_linear_603_1246" x1="1.78463e-08" y1="8.73118" x2="14.5" y2="8.73118" gradientUnits="userSpaceOnUse">
              <stop stopColor="#D500F9"/>
              <stop offset="1" stopColor="#FF1744"/>
          </linearGradient>
      </svg>
      <PauseCircleOutlineIcon sx={{ fill: "url(#paint0_linear_603_1246)" }} />
  </>
)

interface ITaskItemProps {
    taskId?: number,
    title: string,
    onContextMenu?: (e: MouseEvent<HTMLElement>) => void,
    onClick?: (e: MouseEvent<HTMLElement>) => void,
    type?: 'FULL' | 'SHORT',
    completeDate: string,
    onAdd?: () => void,
    creatorAvatar?: string,
    creatorName?: string,
    creatorId: number,
    executors?: IExecutor[],
    onChangeTimer?: (executorId: number, play: boolean) => void,
    onChangeTimerManual: (executorId: number, seconds: number) => void,
    onExecutorComplete?: (executorId: number, completed: boolean) => void,
    completed: boolean,
    accounted: boolean,
    href: string,
    loading?: boolean
}

const TaskItem = ({
                    taskId,
                      title,
                      onClick,
                      onContextMenu,
                      type = 'SHORT',
                      completeDate,
                      onAdd,
                      creatorAvatar,
                      creatorName,
                      creatorId,
                      executors,
                      onChangeTimer,
                      onChangeTimerManual,
                      onExecutorComplete,
                      completed,
                      accounted,
                      href,
                      loading
                  }: ITaskItemProps) => {
    const {data: currentUser} = useGetUserQuery();

    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
    const menuOpen = Boolean(menuAnchor);
    const [activeExecutor, setActiveExecutor] = useState<null | number>(null);

    const [removeExecutor] = useRemoveTaskExecutorMutation();
    const removeExecutorHandler = () => {
        if(activeExecutor) {
            removeExecutor(activeExecutor);
        }
    }

    return (
        <>
            <Menu
                open={menuOpen}
                anchorEl={menuAnchor}
                onClose={() => setMenuAnchor(null)}
            >
                <MenuItem disabled={true}>
                    Напомнить
                </MenuItem>
                <MenuItem onClick={removeExecutorHandler}>
                    Исключить
                </MenuItem>
            </Menu>
            <TableRow
                sx={{
                    opacity: completed ? 0.5 : 1
                }}>
                <TableCell>
                    {taskId}
                </TableCell>
                <TableCell onContextMenu={onContextMenu}>
                    <Link
                        style={{
                            textDecoration: 'none',
                            color: 'inherit'
                        }}
                        to={href}>
                        <DescriptionCell>
                            {title}
                        </DescriptionCell>
                    </Link>
                </TableCell>
                <TableCell>
                    {format(new Date(completeDate), 'dd/MM/Y')}
                </TableCell>
                <TableCell>
                    <Box
                        sx={{
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                        <ExecutorsWrap>
                            {
                                executors?.map(({id, executor: user, is_completed, is_play, total_time}) => (
                                    <Fragment
                                        key={id}>
                                        <Box
                                            sx={{
                                                position: 'relative'
                                            }}>
                                            <Checkbox
                                                icon={currentUser?.id === user.id ? <CheckboxIconActive/> : <CheckboxIcon/>}
                                                checkedIcon={currentUser?.id === user.id ? <CheckboxIconCheckedActive/> : <CheckboxIconChecked/>}
                                                checked={is_completed}
                                                disabled={false}
                                                onClick={e => e.stopPropagation()}
                                                onChange={() => {
                                                    if(onExecutorComplete) {
                                                        onExecutorComplete(id, !is_completed);
                                                    }
                                                }}
                                                sx={{
                                                    position: 'absolute',
                                                    left: '-3px',
                                                    bottom: '0',
                                                    width: '20px',
                                                    height: '20px',
                                                    zIndex: 3,
                                                    padding: 0,
                                                }}
                                            />
                                            <Tooltip
                                                title={user.first_name ? user.first_name : user.email}
                                            >
                                                <Avatar
                                                    src={user.avatar ? process.env.REACT_APP_BACKEND_URL + user.avatar : undefined}
                                                    onContextMenu={e => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        setActiveExecutor(id);
                                                        setMenuAnchor(e.currentTarget);
                                                    }}/>
                                            </Tooltip>
                                        </Box>
                                        <Box
                                            sx={(theme) => ({
                                                marginLeft: '10px',
                                                marginRight: '10px',
                                                color: theme.palette.grey['500'],
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center'
                                            })}
                                        >
                                            <IconButton
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    if (onChangeTimer) {
                                                        onChangeTimer(id, !is_play);
                                                    }
                                                }}
                                                disabled={is_completed || currentUser?.id !== user.id}
                                            >
                                                {
                                                    is_play ?
                                                        (
                                                            !is_completed && currentUser?.id === user.id ?
                                                                <PauseCircleOutlineIconActive/> :
                                                                <PauseCircleOutlineIcon/>
                                                        )
                                                        :
                                                        (
                                                            !is_completed && currentUser?.id === user.id ?
                                                                <PlayCircleFilledWhiteOutlinedIconActive/> :
                                                                <PlayCircleFilledWhiteOutlinedIcon/>
                                                        )
                                                }

                                            </IconButton>
                                            <TaskItemTimer
                                                disabled={currentUser?.id !== user.id}
                                                onSubmit={(seconds) => onChangeTimerManual(id, seconds)}
                                                totalTime={total_time}/>
                                        </Box>
                                    </Fragment>
                                ))
                            }
                        </ExecutorsWrap>
                        {
                            !completed &&
                            <AddButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (onAdd) {
                                        onAdd();
                                    }
                                }}
                            />
                        }
                    </Box>
                </TableCell>
                <TableCell>
                    <Tooltip title={creatorName || ''}>
                        <Avatar src={creatorAvatar}/>
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        {
                            completed && (
                                accounted ?
                                <AttachMoneyIcon sx={{color: '#F5BA21'}}/>:
                                <AttachMoneyIcon/>
                            )
                        }
                    </Box>
                </TableCell>
            </TableRow>
        </>
    );
}

export default TaskItem;
