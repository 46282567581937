import {Box, BoxProps, styled} from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import {ChangeEvent, MutableRefObject} from "react";

interface DnDAreaWrapperProps extends BoxProps {
    dragging?: boolean,
    background?: string,
}

const DnDAreaWrapper = styled(Box)<DnDAreaWrapperProps>(({theme, dragging, background}) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 205,
    alignItems: 'center',
    background: background ? `url(${background}) no-repeat center /contain` : theme.palette.grey['100'],
    border: dragging ? `2px solid ${theme.palette.primary.main}` : `2px dashed ${theme.palette.grey['400']}`,
    padding: '40px',
    position: 'relative'
}));

const Icon = styled(InsertDriveFileIcon)(({theme}) => ({
    width: '40px',
    height: '40px',
    color: theme.palette.grey['500']
}));

const StyledLabel = styled('p')(() => ({
    fontSize: '12px',
    fontWeight: 300,
    textAlign: 'center'
}));

interface IDnDProps {
    label?: string,
    dragging?: boolean,
    showInput?: boolean,
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
    background?: string,
    ref?: MutableRefObject<HTMLDivElement | undefined>
}

const DnD = ({label, dragging, showInput, onChange, background, ref}: IDnDProps) => {
    return (
        <DnDAreaWrapper
            ref={ref}
            background={background} dragging={dragging}>
            {
                showInput && (
                    <input
                        onChange={onChange}
                        multiple={true}
                        type="file" style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        left: 0,
                        top: 0,
                        opacity: 0,
                        cursor: 'pointer'
                    }}/>
                )
            }
            <Icon/>
            <StyledLabel>
                {
                    label ? label :
                        'Перетащите документ сюда, чтобы добавить'
                }

            </StyledLabel>
        </DnDAreaWrapper>
    );
}

export default DnD;
