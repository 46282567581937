import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {Avatar, FormControl, MenuItem} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import {IUser} from "../services/tasks";

interface IUsersSelectProps {
    label: string,
    value: string,
    onChange: (e: SelectChangeEvent<string>) => void,
    users?: IUser[]
}

const UsersSelect = ({value, onChange, label, users}: IUsersSelectProps) => {


    if(!users || users.length < 1) return (
        <p>Нет доступных челов</p>
    )
    return (
        <FormControl sx={{width: '100%'}}>
            <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
            <Select
                labelId='demo-multiple-checkbox-label'
                value={value}
                onChange={onChange}
                sx={{
                    width: '100%',
                    '.MuiSelect-select': {
                        display: 'flex'
                    }
                }}
            >
                {users?.map((user) => (
                    <MenuItem
                        key={user.id} value={user.id}>
                        <Avatar
                            sx={{
                                mr: 3
                            }}
                            src={user.avatar}/>
                        <ListItemText primary={user.first_name ? user.first_name : user.email} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default UsersSelect;
