import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import ruLocale from "date-fns/locale/ru";
import {TextField} from "@mui/material";
import {useState} from "react";
import {useUpdateTaskMutation} from "../../services/tasks";
import {appAlert} from "../../features/appSlice";
import {useDispatch} from "react-redux";


interface ITaskDateFieldProps {
    taskId?: string|number;
    dateTo?: string;
}

const TaskDateField = ({taskId, dateTo}: ITaskDateFieldProps) => {

    const date = dateTo ? new Date(dateTo) : new Date();

    const dispatch = useDispatch();

    const [updateTask, {isLoading}] = useUpdateTaskMutation();
    const saveHandler = (date: Date|null) => {
        if(taskId && date) {
            updateTask({
                id: taskId,
                dateTo: date.toISOString()
            }).unwrap()
                .catch(err => {
                    dispatch(appAlert({title: err.data.detail, status: 'error'}))
                });
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
            <DatePicker
                disabled={isLoading}
                label="Выполнить до"
                value={date}
                onChange={(newValue) => {
                    saveHandler(newValue);
                }}
            />
        </LocalizationProvider>
    )
}

export default TaskDateField;