import {Avatar, Box, Button, IconButton, styled, Tooltip} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CommentIcon from '@mui/icons-material/Comment';
import {IUser} from "../../../../types";
import {format} from "date-fns";
import OverLay from "../../UI/OverLay";


const Wrapper = styled(Box)(() => ({
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    alignItems: 'center'
}));

const ControlBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center'
}));

const Title = styled('span')(() => ({
    display: 'inline-block',
    fontSize: 11,
    fontWeight: 500,
    marginLeft: 10,
    marginRight: 5,
    textTransform: 'uppercase',
    'span': {
        fontWeight: 400,
    }
}));

const MyAvatar = styled(Avatar)(() => ({
    width: 30,
    height: 30
}));

const MyButton = styled(Button)(() => ({
    paddingLeft: 5,
    paddingRight: 5,
    minWidth: 36,
    marginLeft: 3
}));

interface IAdditionControlsProps {
    creator: IUser,
    updater?: IUser|null,
    createdAt: string,
    updatedAt: string,
    onDelete?: () => void,
    onComment?: () => void,
    isLoading?: boolean
}

const AdditionControls = ({creator, updater, createdAt, updatedAt, onDelete, onComment, isLoading}: IAdditionControlsProps) => {
    return (
        <Wrapper>
            {
                isLoading && <OverLay/>
            }
            {
                onComment && (
                    <MyButton
                        onClick={onComment}
                        variant="contained" aria-label="comment">
                        <CommentIcon />
                    </MyButton>
                )
            }
            {
                onDelete && (
                    <MyButton
                        onClick={onDelete}
                        variant="contained" color="error" aria-label="delete">
                        <DeleteIcon />
                    </MyButton>
                )
            }
            <ControlBox>
                <Title>
                    Created: <span>{format(new Date(createdAt), "dd.MM.y HH:mm")}</span>
                </Title>
                <Tooltip title={creator.email}>
                    <MyAvatar src={creator.avatar} />
                </Tooltip>
            </ControlBox>
            {
                updater && (
                    <ControlBox>
                        <Title>
                            Updated: <span>{format(new Date(updatedAt), "dd.MM.y HH:mm")}</span>
                        </Title>
                        <Tooltip title={updater.email}>
                            <MyAvatar src={updater.avatar} />
                        </Tooltip>
                    </ControlBox>
                )
            }
        </Wrapper>
    )
}

export default AdditionControls;